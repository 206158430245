import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import Box from '@mui/material/Box';
import { lazyLoad } from 'utils/loadable';
import { Divider, Paper, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import specialLinks from '../../../assets/specialLinks.json';
import { Post } from 'types/post';

import { Image } from 'mui-image';

export const MayorsBio = lazyLoad(
  () => import('./MayorsBio'),
  module => module.MayorsBioContent,
);

export function MayorsBioContent(props: any) {
  const { pathname } = useLocation();

  const [contentState, setContentState] = React.useState<any>(null);
  const [announcement, setAnnouncement] = React.useState<Post | undefined>(
    undefined,
  );

  const findLinkContentByLink = (link: string) => {
    const _data = specialLinks.find(item => item.uniqueLink === link);
    setContentState(_data?.postContent);
    return _data;
  };

  const onContentStateChange = contentState => {
    setContentState(contentState);
  };

  // React.useEffect(() => {
  //   setAnnouncement(findLinkContentByLink(pathname));
  //   //console.log(findAnnouncementByID(Number(params.announcementId)));
  // }, []);

  return (
    <>
      <Helmet>
        <title>Mayor's Bio</title>
        <meta name="description" content="mayors bio" />
      </Helmet>
      <Box sx={{ p: '15px' }}>
        <Paper
          variant="outlined"
          sx={{
            // mt: '7.5px',
            p: '12px',
            '& a': {
              textDecoration: 'none',
            },
            '& .announcementHeader': {
              color: 'text.primary',
            },
            '& .announcementHeader:hover': {
              color: 'text.secondary',
            },
            backgroundColor: 'primary.main',
            '@media (max-width: 900px)': {
              textAlign: 'center',
            },
            '@media (min-width: 900px)': {},
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
          }}
        >
          <Typography variant="h3" sx={{ color: 'background.paper' }}>
            Mayor's Bio
          </Typography>
        </Paper>
        <Divider />
        <Paper
          variant="outlined"
          sx={{
            my: '15px',
            p: '15px',
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
          }}
        >
          <Image
            src={require(`../../../assets/uploads/mayorsBio.jpg`)}
            duration={1000}
            fit="contain"
            height="40rem"
          />
          <Typography
            sx={{ textAlign: 'center', mb: '15px', mt: '7.5px' }}
            variant="h5"
          >
            ATTY. Virgilio “Nene” Teruel
          </Typography>
          <Typography sx={{ textAlign: 'justify' }}>
            Senior partner at Teruel Law Office
          </Typography>
          <Typography sx={{ textAlign: 'justify' }}>
            Studied at San Beda College of Law
          </Typography>
          <Typography sx={{ textAlign: 'justify' }}>
            Studied Political science at University of San Agustin
          </Typography>
          <Typography sx={{ textAlign: 'justify' }}>
            Lives in Tigbauan, Iloilo, Philippines
          </Typography>
          <Typography sx={{ textAlign: 'justify' }}>
            Married to Maruchi Aldon Teruel
          </Typography>
        </Paper>
        <Paper
          variant="outlined"
          sx={{
            my: '15px',
            p: '15px',
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
          }}
        >
          <Typography sx={{ textAlign: 'justify' }}>
            For efficient, effective and economical governance the purpose of
            which is the general welfare of the municipality and its
            inhabitants, the municipal mayor shall exercise general supervision
            and control over all programs, project, services and activities of
            the municipal government
          </Typography>
        </Paper>
        <Paper
          variant="outlined"
          sx={{
            // mt: '7.5px',
            p: '12px',
            '& a': {
              textDecoration: 'none',
            },
            '& .announcementHeader': {
              color: 'text.primary',
            },
            '& .announcementHeader:hover': {
              color: 'text.secondary',
            },
            backgroundColor: 'primary.main',
            '@media (max-width: 900px)': {
              textAlign: 'center',
            },
            '@media (min-width: 900px)': {},
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
          }}
        >
          <Typography variant="h4" sx={{ color: 'background.paper' }}>
            Mayor's Office Contact Information
          </Typography>
        </Paper>
        <Paper
          variant="outlined"
          sx={{
            my: '15px',
            p: '15px',
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
          }}
        >
          <Typography sx={{ textAlign: 'justify' }}>
            Office of the Municipal Mayor
          </Typography>
          <Typography sx={{ textAlign: 'justify' }}>
            2nd Floor, Tigbauan Municipal Bldg., Tejero St., Brgy. 9, Tigbauan,
            Iloilo
          </Typography>
          <Typography sx={{ textAlign: 'justify' }}>321-2474</Typography>
        </Paper>
        {/* <Paper variant="outlined" sx={{ my: '15px', p: '15px' }}>
          <Image
            src={require(`../../../assets/uploads/mayorsBio.jpg`)}
            duration={0}
            fit="contain"
            height="40rem"
          />
          <Typography
            sx={{ textAlign: 'center', mb: '15px', mt: '7.5px' }}
            variant="h5"
          >
            ATTY. SUZETTE TENEFRANCIA-ALQUISADA
          </Typography>
          <Typography sx={{ textAlign: 'justify' }}>
            She was widowed at a very early age of 30, thus, she took full
            responsibility of rearing her only son Adrian, who is now a
            Registered Nurse by profession.
          </Typography>
          <Typography sx={{ textAlign: 'justify' }}>
            While serving as the Municipal Accountant of Tigbauan, Iloilo, she
            pursued and earned her Masters Degree in Business Administration
            also from University of the Philippines in the Visayas and her
            Bachelor of Laws at the University of San Agustin wherein she
            graduated as one of top five awardees in 2002, inspiring other
            working students that they can also succeed.
          </Typography>
          <Typography sx={{ textAlign: 'justify' }}>
            After successfully hurdling the bar examinations on her first take,
            she heeded to the call of her constituents to enter politics and she
            became the first CPA-Lawyer Councilor of the Municipality of
            Tigbauan for three (3) consecutive terms – from 2004 to 2013. During
            her last term as Sangguniang Bayan Member (2010-213), she was
            elected as the Philippine Councilors League President of Iloilo
            Province and the Regional Chairperson of PCL Region VI. Thus, she
            sat as an ex-officio member of the Sangguniang Panlalawigan of
            Iloilo Province.
          </Typography>
          <Typography sx={{ textAlign: 'justify' }}>
            She was a former Secretary General of the Government Association of
            Certified Public Accountants and an active member of the Integrated
            Bar of the Philippines as well as the Women Law Advocates of the
            Philippines, Iloilo-Chapter.
          </Typography>
          <Typography sx={{ textAlign: 'justify' }}>
            For her, education plays a significant part in bringing out the best
            in every person that is why she accepted the challenge of being a
            teacher herself at the University of San Agustin – College of Law
            teaching Taxation Law before becoming a Mayor of the Municipality of
            Tigbauan.
          </Typography>
          <Typography sx={{ textAlign: 'justify' }}>
            Because of her outstanding accomplishments and achievements as a
            passionate public servant and a private practitioner at the same
            time, she was awarded as one of the Ten Outstanding Women of Iloilo
            in 2012. She was also awarded a Presidential Citation for
            Outstanding Service by the Women Law Advocates of the Philippines.
          </Typography>
          <Typography sx={{ textAlign: 'justify' }}>
            Now on her 2nd term as Municipal Mayor, she is the woman behind the
            taglines “Serbisyo sa Tigbauan Alisto” and “ONE TIGBAUAN” because
            she firmly believes that as a Municipal Mayor, effective and
            efficient governance requires prompt and timely delivery of quality
            service notwithstanding the fact that she cannot do all of these
            alone, but she needs the entire municipality of Tigbauan to be one
            towards achieving the peak of progress and development.
          </Typography>
          <Typography sx={{ textAlign: 'center', my: '15px' }} variant="h5">
            She is a woman of strength and courage…of change and transformation…
            and of vision and action!
          </Typography>
        </Paper> */}
      </Box>
    </>
  );
}
