import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import Box from '@mui/material/Box';
import { lazyLoad } from 'utils/loadable';
import { Divider, Grid, Paper, Typography } from '@mui/material';
import { Person } from '@mui/icons-material';
import Image from 'mui-image';
import { Link as RouterLink } from 'react-router-dom';
export const Offices = lazyLoad(
  () => import('./Offices'),
  module => module.OfficesContent,
);

export function OfficesContent(props: any) {
  return (
    <>
      <Helmet>
        <title>Offices</title>
        <meta name="description" content="Offices" />
      </Helmet>
      <Box
        sx={{
          '& .local': {
            p: 2,
            height: '400px',
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
          },
          p: '15px',
        }}
      >
        {/* <Paper
          sx={{
            mt: '15px',
            p: '7.5px',
            pb: '15px',
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
          }}
          variant="outlined"
        > */}
        <Paper
          variant="outlined"
          sx={{
            // mt: '7.5px',
            p: '12px',
            '& a': {
              textDecoration: 'none',
            },
            '& .announcementHeader': {
              color: 'text.primary',
            },
            '& .announcementHeader:hover': {
              color: 'text.secondary',
            },
            backgroundColor: 'primary.main',
            '@media (max-width: 900px)': {
              textAlign: 'center',
            },
            '@media (min-width: 900px)': {},
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
          }}
        >
          <Typography variant="h4" sx={{ color: 'background.paper' }}>
            Local Offices
          </Typography>
        </Paper>
        <Grid
          spacing={'15px'}
          sx={{
            mt: '7.5px',
            '& .local': {
              boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
            },
          }}
          container
        >
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Paper
              sx={{
                p: '7.5px',
                display: 'flex',
                flexDirection: 'column',
              }}
              variant="outlined"
              className="local"
            >
              <RouterLink
                style={{ textDecoration: 'none' }}
                to="/local-offices/accounting-office"
              >
                <Typography sx={{ textAlign: 'center', fontWeight: 'bolder' }}>
                  Accounting Office
                </Typography>
              </RouterLink>

              <Person sx={{ fontSize: '10rem', alignSelf: 'center' }} />
              <Typography variant="caption">
                An accounting officer handles the financial record keeping of an
                organization. Accounting officers prepare invoices, record
                payments, and manage the expenses of the organization.
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Paper sx={{ p: '7.5px' }} variant="outlined" className="local">
              <RouterLink
                style={{ textDecoration: 'none' }}
                to="/local-offices/agriculture-office"
              >
                <Typography sx={{ textAlign: 'center', fontWeight: 'bolder' }}>
                  Agriculture Office
                </Typography>
              </RouterLink>
              <Person sx={{ fontSize: '10rem', alignSelf: 'center' }} />
              <Typography variant="caption">
                An agriculture officer assists farmers in cultivating the most
                productive crops using their agricultural knowledge ensuring
                that all farming operations and products are in line with the
                requirements of local and state regulations by examinkng,
                auditing, and analyzing agricultural products.
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Paper sx={{ p: '7.5px' }} variant="outlined" className="local">
              <RouterLink
                style={{ textDecoration: 'none' }}
                to="/local-offices/assessors-office"
              >
                <Typography sx={{ textAlign: 'center', fontWeight: 'bolder' }}>
                  Assessors Office
                </Typography>
              </RouterLink>

              <Person sx={{ fontSize: '10rem', alignSelf: 'center' }} />
              <Typography variant="caption">
                The assesor officer exercises supervision and control over all
                programs, projects, and activities of Real Property Tax
                administration of the city and executes all laws and ordinances
                governing sound implementation appraisal and assessment of real
                property.
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Paper sx={{ p: '7.5px' }} variant="outlined" className="local">
              <RouterLink
                style={{ textDecoration: 'none' }}
                to="/local-offices/budget-office"
              >
                <Typography sx={{ textAlign: 'center', fontWeight: 'bolder' }}>
                  Budget Office
                </Typography>
              </RouterLink>

              <Person sx={{ fontSize: '10rem', alignSelf: 'center' }} />
              <Typography variant="caption">
                The Budget Officer implements budgeting and financial record
                keeping procedures to ensure efficient coordination of various
                departmental, grant, and designated accounts, maintains accurate
                information regarding the financial status of the cost center,
                advises the immediate supervisor regarding financial decisions.
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Paper sx={{ p: '7.5px' }} variant="outlined" className="local">
              <RouterLink
                style={{ textDecoration: 'none' }}
                to="/local-offices/engineering-office"
              >
                <Typography sx={{ textAlign: 'center', fontWeight: 'bolder' }}>
                  Engineering Office
                </Typography>
              </RouterLink>
              <Person sx={{ fontSize: '10rem', alignSelf: 'center' }} />
              <Typography variant="caption">
                Provides engineering services to the City Government, including
                investigation and survey, engineering design, feasibility
                studies and project management; controls construction,
                improvement, maintenance, and repair of roads, bridges, and
                other engineering works/projects of the City Government
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Paper sx={{ p: '7.5px' }} variant="outlined" className="local">
              <RouterLink
                style={{ textDecoration: 'none' }}
                to="/local-offices/environmental-and-natural-resources-office"
              >
                <Typography sx={{ textAlign: 'center', fontWeight: 'bolder' }}>
                  Environmental & Natural Resources Office
                </Typography>
              </RouterLink>

              <Person sx={{ fontSize: '10rem', alignSelf: 'center' }} />
              <Typography variant="caption">
                The office of MENRO is ensures an environment friendly
                municipality through maintenance of cleanliness and sanitation,
                regulation of natural wealth, reforestation and material
                recovery of waste materials, and develops plans and strategies
                for the environmental programs and projects.
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Paper sx={{ p: '7.5px' }} variant="outlined" className="local">
              <RouterLink
                style={{ textDecoration: 'none' }}
                to="/local-offices/local-civil-registrar-office"
              >
                <Typography sx={{ textAlign: 'center', fontWeight: 'bolder' }}>
                  Local Civil Registrar Office
                </Typography>
              </RouterLink>

              <Person sx={{ fontSize: '10rem', alignSelf: 'center' }} />
              <Typography variant="caption">
                The Civil Registrar’s Office is mandated to register the birth,
                marriage and death of its constituents.
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Paper sx={{ p: '7.5px' }} variant="outlined" className="local">
              <RouterLink
                style={{ textDecoration: 'none' }}
                to="/local-offices/market-and-slaughter-house"
              >
                <Typography sx={{ textAlign: 'center', fontWeight: 'bolder' }}>
                  Market and Slaughter House
                </Typography>
              </RouterLink>
              <Person sx={{ fontSize: '10rem', alignSelf: 'center' }} />
              <Typography variant="caption">
                The office of the market and slaughterhouse is the sole division
                of the Mayor’s Office that implements the proper slaughtering of
                livestock for human consumption. It is the only division that
                produces finish product that is clearly subjected to scrutiny of
                public being served on a daily basis.
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Paper sx={{ p: '7.5px' }} variant="outlined" className="local">
              <RouterLink
                style={{ textDecoration: 'none' }}
                to="/local-offices/municipal-disaster-risk-reduction-management-office"
              >
                <Typography sx={{ textAlign: 'center', fontWeight: 'bolder' }}>
                  Municipal Disaster Risk Reduction Management Office
                </Typography>
              </RouterLink>

              <Person sx={{ fontSize: '10rem', alignSelf: 'center' }} />
              <Typography variant="caption">
                The primary objective MDRRMO is to ensure effective and
                efficient implementation of civil protection programme through
                an integrated, multi-sectoral and community based approach and
                strategies for the protection and preservation of life, property
                and environment.
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Paper sx={{ p: '7.5px' }} variant="outlined" className="local">
              <RouterLink
                style={{ textDecoration: 'none' }}
                to="/local-offices/planning-office"
              >
                <Typography sx={{ textAlign: 'center', fontWeight: 'bolder' }}>
                  Planning Office
                </Typography>
              </RouterLink>

              <Person sx={{ fontSize: '10rem', alignSelf: 'center' }} />
              <Typography variant="caption">
                The Municipal Planning Office quality services to plan and
                formulate economic, social, infrastructure, environmental and
                other development programs for inter-department coordination in
                pursuit of the vision and mission of the local government.
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Paper sx={{ p: '7.5px' }} variant="outlined" className="local">
              {' '}
              <RouterLink
                style={{ textDecoration: 'none' }}
                to="/local-offices/social-welfare-and-development-office"
              >
                <Typography sx={{ textAlign: 'center', fontWeight: 'bolder' }}>
                  Social Welfare & Development Office
                </Typography>
              </RouterLink>
              <Person sx={{ fontSize: '10rem', alignSelf: 'center' }} />
              <Typography variant="caption">
                Provide social protection and promote the rights and welfare of
                the poor, vulnerable and disadvantaged individuals, families and
                communities to contribute to the poverty alleviation an
                empowerment through social welfare and development policies,
                developed appropriate programs, projects and services.
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Paper sx={{ p: '7.5px' }} variant="outlined" className="local">
              <RouterLink
                style={{ textDecoration: 'none' }}
                to="/local-offices/treasurers-office"
              >
                <Typography sx={{ textAlign: 'center', fontWeight: 'bolder' }}>
                  Treasurers Office
                </Typography>
              </RouterLink>

              <Person sx={{ fontSize: '10rem', alignSelf: 'center' }} />
              <Typography variant="caption">
                The Office of the Municipal Treasurer is mandated to collect
                taxes, fees and other revenues and to take custody and exercise
                proper management if LGU funds.
              </Typography>
            </Paper>
          </Grid>
        </Grid>
        {/* </Paper> */}
        {/* <Paper sx={{ mt: '15px', p: '7.5px', pb: '15px' }} variant="outlined"> */}
        <Paper
          variant="outlined"
          sx={{
            // mt: '7.5px',
            p: '12px',
            '& a': {
              textDecoration: 'none',
            },
            '& .announcementHeader': {
              color: 'text.primary',
            },
            '& .announcementHeader:hover': {
              color: 'text.secondary',
            },
            backgroundColor: 'primary.main',
            '@media (max-width: 900px)': {
              textAlign: 'center',
            },
            '@media (min-width: 900px)': {},
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
            mt: 2,
          }}
        >
          <Typography variant="h4" sx={{ color: 'background.paper' }}>
            National Offices
          </Typography>
        </Paper>
        <Grid
          spacing={'15px'}
          container
          sx={{
            mt: '7.5px',
            '& .local': {
              boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
            },
          }}
        >
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Paper sx={{ p: '7.5px' }} variant="outlined" className="local">
              <RouterLink
                style={{ textDecoration: 'none' }}
                to="/national-offices/bureau-of-fire-protection"
              >
                <Typography sx={{ textAlign: 'center', fontWeight: 'bolder' }}>
                  Bureau of Fire Protection
                </Typography>
              </RouterLink>

              <Box pt={2} pb={2}>
                <img
                  src="https://bfp.gov.ph/wp-content/uploads/2017/05/bfp-new-logo.png"
                  width="150px"
                  alt="BFP-img"
                />
              </Box>
              <Typography variant="caption">
                The BFP is responsible for ensuring public safety through the
                prevention or suppression of all destructive fires on buildings,
                houses, and other similar structure, forests, and land
                transportation vehicles and equipment, ships/vessels docked at
                piers, wharves or anchored at major seaports, petroleum industry
                installations.
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Paper sx={{ p: '7.5px' }} variant="outlined" className="local">
              <RouterLink
                style={{ textDecoration: 'none' }}
                to="/national-offices/comelec"
              >
                <Typography sx={{ textAlign: 'center', fontWeight: 'bolder' }}>
                  Commission on Elections
                </Typography>
              </RouterLink>

              <Box pt={2} pb={2}>
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/73/Commission_on_Elections_%28COMELEC%29.svg/1200px-Commission_on_Elections_%28COMELEC%29.svg.png"
                  width="150px"
                  alt="comelec-img"
                />
              </Box>
              <Typography variant="caption">
                The Commission on Elections (COMELEC) is the primary government
                agency responsible for the conduct of regular and special
                elections in the country, fiscally-autonomous body free from
                political interference or influence from the three branches of
                government, which makes possible the conduct of free, fair, and
                honest elections.
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Paper sx={{ p: '7.5px' }} variant="outlined" className="local">
              <RouterLink
                style={{ textDecoration: 'none' }}
                to="/national-offices/department-of-agrarian-reform"
              >
                <Typography sx={{ textAlign: 'center', fontWeight: 'bolder' }}>
                  Department of Agrarian Reform
                </Typography>
              </RouterLink>
              <Box pt={2} pb={2}>
                <img
                  src="https://media.dar.gov.ph/source/2019/02/27/dar-logo-png-3.png"
                  width="150px"
                  alt="dar-img"
                />
              </Box>
              <Typography variant="caption">
                The Department of Agrarian Reform is an executive department of
                the Philippine government responsible for the redistribution of
                agrarian land in the Philippines.
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Paper sx={{ p: '7.5px' }} variant="outlined" className="local">
              <RouterLink
                style={{ textDecoration: 'none' }}
                to="/national-offices/department-of-interior-and-local-government"
              >
                <Typography sx={{ textAlign: 'center', fontWeight: 'bolder' }}>
                  Department of Interior and Local Government
                </Typography>
              </RouterLink>
              <Box pt={2} pb={2}>
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/c/c9/Department_of_the_Interior_and_Local_Government_%28DILG%29_Seal_-_Logo.svg"
                  width="150px"
                  alt="dilg-img"
                />
              </Box>
              <Typography variant="caption">
                The Department of the Interior and Local Government, abbreviated
                as DILG, is the executive department of the Philippine
                government responsible for promoting peace and order, ensuring
                public safety and strengthening local government capability
                aimed towards the effective delivery of basic services to the
                citizenry.
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Paper sx={{ p: '7.5px' }} variant="outlined" className="local">
              <RouterLink
                style={{ textDecoration: 'none' }}
                to="/national-offices/population-commmission"
              >
                <Typography sx={{ textAlign: 'center', fontWeight: 'bolder' }}>
                  Population Commission
                </Typography>
              </RouterLink>
              <Box pt={2} pb={2}>
                <img
                  src="https://rpo5.popcom.gov.ph/wp-content/uploads/2019/05/CPD-1024x999.png"
                  width="150px"
                  alt="popcom-img"
                />
              </Box>
              <Typography variant="caption">
                The Commission on Population (POPCOM) was mandated to serve as
                the central coordinating and policy making body of the
                government in the field of population. The Republic Act 6365,
                known as the Population Act of the Philippines was enacted into
                law by Congress, creating the Commission on Population.
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Paper sx={{ p: '7.5px' }} variant="outlined" className="local">
              <RouterLink
                style={{ textDecoration: 'none' }}
                to="/national-offices/philippine-national-police"
              >
                <Typography sx={{ textAlign: 'center', fontWeight: 'bolder' }}>
                  Philippine National Police
                </Typography>
              </RouterLink>
              <Box pt={2} pb={2}>
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/98/Philippine_National_Police_seal.svg/1200px-Philippine_National_Police_seal.svg.png"
                  width="150px"
                  alt="pnp-img"
                />
              </Box>
              <Typography variant="caption">
                The Philippine National Police (PNP) is tasked with enforcing
                the law, to prevent and control crimes, maintain peace and
                order, and ensure public safety and internal security with the
                active support of the community.
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Paper sx={{ p: '7.5px' }} variant="outlined" className="local">
              <RouterLink
                style={{ textDecoration: 'none' }}
                to="/national-offices/post-office"
              >
                <Typography sx={{ textAlign: 'center', fontWeight: 'bolder' }}>
                  Post Office
                </Typography>
              </RouterLink>

              <Box pt={2} pb={2}>
                <img
                  src="https://upload.wikimedia.org/wikipedia/en/thumb/c/c3/Phlpost_2012_logo.svg/1280px-Phlpost_2012_logo.svg.png"
                  width="150px"
                  alt="postoffice-img"
                />
              </Box>
              <Typography variant="caption">
                The Philippine Postal Corporation, abbreviated as PHLPost, is a
                government-owned and controlled corporation responsible for
                providing postal services in the Philippines.
              </Typography>
            </Paper>
          </Grid>
        </Grid>
        {/* </Paper> */}
      </Box>
    </>
  );
}
