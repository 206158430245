import * as React from 'react';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import { Paper } from '@mui/material';
import Masonry from '@mui/lab/Masonry';
const itemData = [
  {
    img: require('../../../../assets/tourism/bearland/1.jpg'),
    title: 'Bearland Beach Resort',
  },
  {
    img: require('../../../../assets/tourism/bearland/2.jpg'),
    title: 'Bearland Beach Resort',
  },
  {
    img: require('../../../../assets/tourism/bearland/3.jpg'),
    title: 'Bearland Beach Resort',
  },
  {
    img: require('../../../../assets/tourism/bearland/4.jpg'),
    title: 'Bearland Beach Resort',
  },
  {
    img: require('../../../../assets/tourism/bearland/5.jpg'),
    title: 'Bearland Beach Resort',
  },
  {
    img: require('../../../../assets/tourism/bearland/6.jpg'),
    title: 'Bearland Beach Resort',
  },
  {
    img: require('../../../../assets/tourism/dreamersValley/1.jpg'),
    title: 'Dreamers Valley Camp and Resort',
  },
  {
    img: require('../../../../assets/tourism/dreamersValley/2.jpg'),
    title: 'Dreamers Valley Camp and Resort',
  },
  {
    img: require('../../../../assets/tourism/dreamersValley/3.jpg'),
    title: 'Dreamers Valley Camp and Resort',
  },
  {
    img: require('../../../../assets/tourism/dreamersValley/4.jpg'),
    title: 'Dreamers Valley Camp and Resort',
  },
  {
    img: require('../../../../assets/tourism/reynaldos/1.jpg'),
    title: 'Reynaldos Beach Resort',
  },
  {
    img: require('../../../../assets/tourism/reynaldos/2.jpg'),
    title: 'Reynaldos Beach Resort',
  },
  {
    img: require('../../../../assets/tourism/solymar/1.jpg'),
    title: 'Sol y Mar Beach Resort',
  },
  {
    img: require('../../../../assets/tourism/solymar/2.jpg'),
    title: 'Sol y Mar Beach Resort',
  },
  {
    img: require('../../../../assets/tourism/solymar/3.jpg'),
    title: 'Sol y Mar Beach Resort',
  },
  {
    img: require('../../../../assets/tourism/solymar/4.jpg'),
    title: 'Sol y Mar Beach Resort',
  },
  {
    img: require('../../../../assets/tourism/solymar/5.jpg'),
    title: 'Sol y Mar Beach Resort',
  },
  {
    img: require('../../../../assets/tourism/solymar/6.jpg'),
    title: 'Sol y Mar Beach Resort',
  },
  {
    img: require('../../../../assets/tourism/solymar/7.jpg'),
    title: 'Sol y Mar Beach Resort',
  },
  {
    img: require('../../../../assets/tourism/solymar/8.jpg'),
    title: 'Sol y Mar Beach Resort',
  },
  {
    img: require('../../../../assets/tourism/solymar/9.jpg'),
    title: 'Sol y Mar Beach Resort',
  },
  {
    img: require('../../../../assets/tourism/solymar/10.jpg'),
    title: 'Sol y Mar Beach Resort',
  },
];
export default function QuiltedImageList() {
  return (
    <Box sx={{ width: '100%', p: '7px' }}>
      {/* <ImageList
        sx={{
          '& img': {
            '-webkit-transition': 'all 0.5s ease',
            '-moz-transition': 'all 0.5s ease',
            '-ms-transition': 'all 0.5s ease',
            transition: 'all 0.5s ease',
          },
          '& img:hover': {
            transform: 'scale(1.05)',
          },
        }}
      >
        {itemData.map(item => (
          <Paper
            variant="outlined"
            sx={{ p: '15px', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}
          >
            <ImageListItem key={item.img}>
              <img
                src={`${item.img}?w=248&fit=crop&auto=format`}
                srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                alt={item.title}
                loading="lazy"
              />
              <ImageListItemBar
                title={item.title}
                // subtitle={<span>by: {item.author}</span>}
                position="below"
              />
            </ImageListItem>
          </Paper>
        ))}
      </ImageList> */}
      <Masonry
        sx={{
          '& img': {
            '-webkit-transition': 'all 0.5s ease',
            '-moz-transition': 'all 0.5s ease',
            '-ms-transition': 'all 0.5s ease',
            transition: 'all 0.5s ease',
          },
          '& img:hover': {
            transform: 'scale(1.05)',
          },
        }}
        columns={2}
        spacing={2}
      >
        {itemData.map((item, index) => (
          <div key={index}>
            <img
              src={`${item.img}?w=162&auto=format`}
              srcSet={`${item.img}?w=162&auto=format&dpr=2 2x`}
              alt={item.title}
              loading="lazy"
              style={{
                display: 'block',
                width: '100%',
              }}
            />
          </div>
        ))}
      </Masonry>
    </Box>
  );
}
