import { combineReducers } from 'redux';

const apiDataInitialState = {
  supplierData: [],
  materialData: [],
  projectData: [],
  bakProjectData: [],
  projectDataSel: [],
  POData: [],
  PRData: [],
  unitData: [],
  itemData: [],
  actorData: [],
  userData: [],
  userType: [],
};

const navDataInitialState = {
  announcements: [],
  isAnnouncementsLoading: false,
  posts: [],
  isPostsLoading: false,
  selectedNav: '',
  executiveOrders: [],
  resolutions: [],
  ordinances: [],
  visitorData: { uniqueKey: '', Ip: '' },
  visitorPostInteractionsId: [],
};
const globalDocViewerInitialState = {
  isOpen: false,
  docFile: null,
  fileName: '',
  uniqueID: '',
};

function globalDocViewer(state = globalDocViewerInitialState, action) {
  switch (action.type) {
    case 'UI_TOGGLE_DOC_VIEWER':
      return Object.assign({}, state, {
        ...state,
        docFile: action.payload.docFile,
        isOpen: action.payload.data,
      });
    case 'UI_TOGGLE_DOC_VIEWER_WDATA':
      return Object.assign({}, state, {
        ...state,
        docFile: action.payload.docFile,
        isOpen: action.payload.data,
        fileName: action.payload.fileName,
        uniqueID: action.payload.uniqueID,
      });
    case 'UI_STORE_DOC':
      return Object.assign({}, state, {
        ...state,

        docFile: action.payload.data,
      });
    default:
      return state;
  }
}

function navData(state = navDataInitialState, action) {
  switch (action.type) {
    case 'API_STORE_POSTS':
      return Object.assign({}, state, {
        ...state,

        posts: action.payload.data,
      });
    case 'API_STORE_RESOLUTIONS':
      return Object.assign({}, state, {
        ...state,

        resolutions: action.payload.data,
      });
    case 'API_STORE_EXECUTIVE_ORDERS':
      return Object.assign({}, state, {
        ...state,

        executiveOrders: action.payload.data,
      });
    case 'API_STORE_ORDINANCES':
      return Object.assign({}, state, {
        ...state,

        ordinances: action.payload.data,
      });
    case 'API_STORE_ANNOUNCEMENTS':
      return Object.assign({}, state, {
        ...state,
        announcements: action.payload.data,
      });
    case 'API_STORE_NAV':
      return Object.assign({}, state, {
        ...state,
        selectedNav: action.payload.data,
      });
    case 'API_STORE_VISITOR_DATA':
      return Object.assign({}, state, {
        ...state,
        visitorData: action.payload,
      });
    case 'API_STORE_VISITOR_POST_INTERACTIONS':
      return Object.assign({}, state, {
        ...state,
        userPostInteractionsId: action.payload,
      });

    default:
      return state;
  }
}

function apiData(state = apiDataInitialState, action) {
  switch (action.type) {
    case 'API_STORE_PR':
      return Object.assign({}, state, {
        ...state,
        PRData: action.payload.data,
      });
    case 'API_STORE_USER_TYPE':
      let userType = [];

      for (let i = 0; i < action.payload.data.types.length; i++) {
        const _e = action.payload.data.types[i];
        userType.push(_e.name);
      }

      return Object.assign({}, state, {
        ...state,
        userType: userType,
      });

    default:
      return state;
  }
}

const reducer = combineReducers({
  apiData,
  navData,
  globalDocViewer,
});

export default reducer;
