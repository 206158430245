import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import Box from '@mui/material/Box';
import { lazyLoad } from 'utils/loadable';
import { Divider, Paper, Typography } from '@mui/material';

export const AboutTigbauan = lazyLoad(
  () => import('./AboutTigbauan'),
  module => module.AboutTigbauanContent,
);

export function AboutTigbauanContent(props: any) {
  return (
    <>
      <Helmet>
        <title>About Tigbauan</title>
        <meta name="description" content="About Tigbauan" />
      </Helmet>
      <Paper
        variant="outlined"
        sx={{
          // mt: '7.5px',
          p: '12px',
          mb: 2,
          '& a': {
            textDecoration: 'none',
          },
          '& .announcementHeader': {
            color: 'text.primary',
          },
          '& .announcementHeader:hover': {
            color: 'text.secondary',
          },
          backgroundColor: 'primary.main',
          '@media (max-width: 900px)': {
            textAlign: 'center',
          },
          '@media (min-width: 900px)': {},
          boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
        }}
      >
        <Typography variant="h3" sx={{ color: 'background.paper' }}>
          About Tigbauan
        </Typography>
      </Paper>
      <Box
        sx={{
          p: '15px',
          boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
          // minHeight: '550px',
          backgroundColor: 'background.paper',
        }}
      >
        <Box
          sx={{
            pt: '15px',
            '& p': { textAlign: 'justify', py: '10px' },
            '& h4': { py: '15px' },
            '& h5': { py: '15px' },
          }}
        >
          <Typography variant="h4" sx={{ py: '15px' }}>
            History of Tigbauan
          </Typography>
          <Typography>
            The facts and events presented here are limited, taken from notes
            and writings of local historians but not enough to comprehensively
            relate the history of Tigbauan.
          </Typography>
          <Typography>
            Like many other towns in Panay, the history of Tigbauan can be
            traced back from the Spanish era to date. Nevertheless, there is an
            existing anecdotal report that the economic activity in the Island
            of Panay had been existing long before the arrival of the Spanish
            conquistadors. Panay had an existing trade with China and trading
            was widespread along coastal towns of Panay.
          </Typography>
          <Typography>
            The name Tigbauan is believed to come from the name of a tall grass
            called reed, Anthisteria cilleta, and locally called “tigbao” which
            grew abundantly in the place then. The area at which this species of
            grass was abundant was referred to by the natives as “katigbauan”.
            Whenever visitors ask for the name of the place, natives pointed to
            the grass and in the course of time, the place became popularly
            known as Tigbauan.
          </Typography>
          <Typography>
            The town was established in 1575 with Esteban Rodriguez de Figueroa
            as overseer. However, during this period, there was no separation of
            power between the church and the state, and so the friars being most
            powerful at the time were still the ones who ruled.
          </Typography>
          <Typography>
            The local leadership of Tigbauan had been held by the Agustinian and
            Jesuit friars. The Jesuits established the first boarding school in
            the Philippines as exclusive institution that catered to the
            children of Spaniards and prominent Filipino families. The
            Agustinians ruled Tigbauan for 175 years but during their
            administration they focused more on religious and cultural education
            and left aside other sector needs. Moreover, the Agustinians paved
            the way for the establishment of a local political system headed by
            a captain, built a cultural landmark, the Tigbauan Parish Church,
            which now serves as a tourist attraction in town due to its baroque
            architecture even if only the façade of it remained from the ravages
            of war. Francisco Valle became the first captain in 1779,
            thereafter; native capitanes serve as administrators until 1900.
            Infrastructure and facilities development during the term of local
            administrators was very sluggish and started only in 1870 when Mr.
            Aguedo Teruel became the administrator. For a span of 91 years,
            local administrators did nothing to improve the place and to enhance
            the socio-economic situations of the people may be because of the
            short tenure given to each administrator. For tenure of one year,
            one cannot really expect significant accomplishment. The following
            capitanes served the municipality during these years.
          </Typography>
          <Typography>
            The Spanish surrendered Iloilo to the Americans in December 1898.
            However, the latter got control of the place only in 1900 due to the
            resistance offered by the Ilongo revolutionaries. In 1901, the
            Americans instituted the civil government, addressed the local
            administrator as municipal president or municipal mayor thereby
            abolishing the position classification of Capitan.
          </Typography>
          <Typography>
            The first municipal mayor was Constantino Benedicto. It was during
            this time that the first group of American volunteers, the
            Thomasites, arrived and wanted to put up an educational institution
            in Tigbauan but was driven out by the resistance of the people
            towards the plan. Their group settled in Dumaguete where they
            founded what is now the famous Siliman University. The institution
            should have made Tigbauan the center of education and the hub of
            economic activities in the Southern part of Iloilo.
          </Typography>
          <Typography>
            Significant accomplishment at this time included the development of
            infrastructures. Schools and markets were constructed, urban streets
            were concreted and barangay roads established. The progress of
            development was suddenly hindered with the outbreak of the Second
            World War on December 8, 1941. At dawn of December 16, 1942, the
            Japanese invasion forces landed in Panay on the beach of Tigbauan
            and Oton. At that time the local administrators were Salvador Tueres
            (1941-1943) and Concordia Palacios (1944-1945).
          </Typography>
          <Typography>
            The Japanese occupation was short-lived, but awfully devastating and
            turbulent. In 1943, the Japanese forces penetrated Brgy. Parara and
            Napnapan where they beheaded and gunned down many residents.
          </Typography>
          <Typography>
            On March 18, 1945, the American Liberation Forces landed in Tigbauan
            on the shores of Brgy. Parara and Buyu-an. Panay was officially
            declared as liberated on March 22, 1945. This brought to an end the
            nightmares of the people of Tigbauan under the Japanese invasion
            forces.
          </Typography>
          <Typography>
            In 1946, the municipal mayor was Jose B. Torrefranca. This year and
            the last few years of the post-war period were considered years of
            difficulties for the people of Tigbauan. The town was in shambles.
            Some of the public building and private houses were burned or
            destroyed. Other post-war municipal mayors were: forces.
          </Typography>
          <Typography>
            The municipality was able to regain political and socio-economic
            stability in the middle of the post-war period. Feeder roads within
            the town connecting almost all the barangays were constructed. Urban
            roads were paved, new schools were built and old ones were
            rehabilitated. Infrastructure projects, both in the urban and rural
            areas were undertaken by the local and national government to
            enhance the development of the municipality. This signaled the
            return of productive years for Tigbauan.
          </Typography>
          <Typography>
            Greater achievements were attained by the municipality during the
            term of the Hon. Mayor Myrna M. Torres. Significant and tangible
            among which were the improvement of the town plaza and the municipal
            hall, acquisition of heavy equipment’s which are necessary for the
            construction of more farm to market roads and improvement of
            existing ones, and collection and disposal of garbage thus
            maintaining the cleanliness and sanitation of the municipality. A
            fire truck, monitoring van, patrol car, and ambulance were also
            acquired with her desire to provide immediate response to the call
            for peace and safety of the people of Tigbauan. The education of the
            children was always the priority of the people of Tigbauan. With the
            establishment of a tertiary school in the municipality, many of the
            children graduated college, particularly those who have completed BS
            Marine Transportation. It is no wonder why there are now many seamen
            from Tigbauan who are now gainfully employed in various ships plying
            international route. Many ventured abroad for economic reasons as
            nurse, physician, dentist, IT expert, engineer, skilled worker, and
            some as domestic helper. Many more professionals will go out of
            Tigbauan as there are many opportunities offered by various legal
            recruiters. Where the people of Tigbauan are now is a step forward
            where they want to be 10 years from now.
          </Typography>
          <Typography variant="h4" sx={{ color: 'background.paper' }}>
            Physical Features
          </Typography>

          <Typography variant="h5">Location</Typography>
          <Typography>
            The municipality of Tigbauan is a coastal town 23 kilometers
            southwest of Iloilo City. It is one of the seven (7) towns
            comprising the First District of the Province of Iloilo which lies
            between 10º40’30” latitude and 122º22’30” longitude. It is bounded
            by the towns of Leon on the Northwest, San Miguel on the Northeast,
            Oton on the East, Guimbal to the West, and by the Iloilo Strait on
            the South.
          </Typography>
          <Typography variant="h5">Land Area and Waters</Typography>
          <Typography>
            Tigbauan is a second class municipality with a land area of 8,889 ha
            or 88.89 sq. km. The urban core, consisting of 10 barangays, has an
            area of 169 hectares or 1.69 square kilometers representing 1.90% of
            the total area of the municipality while the 42 rural barangays
            occupy a total area of 8,720 ha or 80.720 square kilometers
            equivalent to 98.10%. The municipality’s coastline is an 8-km.
            stretch spanning 10 coastal barangays from Bgy. Barroc down to Bgy.
            Buyuan. The municipal waters covered by this coastline serve as
            fishing grounds for both migrating and endemic fishes. The inland
            bodies of water consisted of two (2) main rivers, namely: the
            Sibalom River and the Tacuyong River. The latter joins the Sibalom
            River at the Western side of the urban core barangays before flowing
            into the Panay Gulf. There are also creeks and natural springs which
            are used as sources of water supply for people in the rural areas.
          </Typography>
          <Typography variant="h5">Topography</Typography>
          <Typography>
            The municipality, generally, has a flat terrain. The plains cover an
            area of 6,667 ha or about 75% of the total area of the municipality.
            Rolling hills covering an area of about 1,518 hectares are located
            along boundaries of neighboring towns, excluding Oton and San
            Miguel.
          </Typography>
          <Typography variant="h5">Soil Types</Typography>
          <Typography>
            There are four types of soil found in Tigbauan. These are the Sta.
            Rita Clay Loam which are located in Bgy. Sipitan and Bgy. Linobayan.
            Umingan Fine Sandy Loam is found in Cordova Norte, Cordova Sur,
            portions of Bgys. Napnapan Norte, Napnapan Sur, Bitas, Bagumbayan,
            Dorong-an, Bayuco, Buenavista, San Rafael and Poblacion. Alimodian
            Clay Loam is found in Bgy. Bantud, Baguingin, Bangkal, Olo Barroc,
            Barroc, Namocon, Parara Norte, Parara Sur and Buyu-an. Alimodian
            Silt Loam is found in the rest of the rural Barangays.
          </Typography>
          <Typography variant="h5">Climate</Typography>
          <Typography>
            Tigbauan has two pronounced seasons: dry from November to April and
            wet during the rest of the year. There are two prevailing winds: the
            Southeast Monsoon (Amihan) and the Southwest Monsoon (Habagat).
          </Typography>
          <Typography variant="h5">
            Population Levels and Growth Pattern
          </Typography>
          <Typography>
            Based on the National Statistics Office Census of 2010, the
            municipality recorded a fluctuating growth rate from 1903 to 1960
            (Table Dem-1) and steadily increased with a growth rate of 2.43% in
            1990. From 2000 to 2010, population growth rate increased from 1.39%
            to 1.55%.
          </Typography>
          <Typography>
            It was during the period of 1981 to 1990 that the municipality
            registered the highest in population growth with 43,934 persons.
            This represents an increase of 9,394 persons or 27.20% higher than
            those in 1976 to 1980 which is 34,540 persons. The average annual
            growth rate from 1976 to 1980 was 2.37%. This is higher than those
            recorded for the Province of Iloilo for the same period.
          </Typography>
          <Typography>
            The high population growth for the period 1981-1990 may be due to
            the establishment of ILECO I, SEAFDEC/AQD, St. Therese MTC and the
            location of police camp in the municipality. Some of the employees
            of these institutions opted to establish their residences in the
            municipality. On the other hand, the decline in growth rate from
            1991-2010 may be due to out-migration of the municipality’s
            professionals and skilled workers/non-professionals who seek for
            greener pasture outside of the municipality. It was likewise during
            this period when a great demandoutside for professionals such as
            physicians, nurses, physical therapist, engineers, and midwives
            started. There was also a big demand for skilled workers and
            domestic helpers abroad.
          </Typography>
          <Typography variant="h5">
            Population, Household Distribution and Density Levels
          </Typography>
          <Typography>
            The NSO Census of 2010 data on population distribution (Table Dem-3)
            and population densities by barangay show the unbalanced spatial
            distribution of the municipality’s population. Among the 52
            barangays of the municipality, the most densely populated are those
            within the poblacion or those comprising the 10 urban barangays. The
            urban barangays has the highest population concentration of 11,075
            persons in an area of 169 has. or a population density of 66 persons
            per hectare. The rural barangays post a population of 51,274 persons
            in an area of 8,720 has. or a population density of 6 persons per
            hectare. The 2010 NSO Census on Population shows a total population
            in the municipality of 58,814 and a population density of 7 persons
            per hectare. The data (Table Dem-2) reveals the same trend for the
            number of household. The urban barangays shows a total household of
            2,194 or an average household density of 13 household per hectare
            while the rural barangays have 10,509 households or an average
            household density of 1.2 household per hectare.
          </Typography>
          <Typography>
            Of the most densely populated barangays, Barangay 2 ranks first with
            a population density of 147 persons per hectare (Table Dem-3),
            followed by Barangay 6 and Barangay 5 with a population density of
            126 and 125 persons per ha., respectively. On the other hand, the
            three sparsely populated barangays come from the rural group.
            Ranking them from the bottom, the barangay which ranks lowest is
            Barangay Cansilayan with 1.80 persons per ha. followed by Barangays
            Isian and Supa with 1.87 and 2.00 persons per ha., respectively. The
            data show that accessibility to the poblacion as one of the factors
            affecting concentration of population. The three bottom ranking
            barangays and those with less than 3 persons per ha. are located in
            the remote areas of the municipality with poor farm-to-market roads
            that are not passable during rainy days. The data generally indicate
            that the barangays with access to good roads have higher
            concentration of population
          </Typography>
        </Box>
      </Box>
    </>
  );
}
