import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import Box from '@mui/material/Box';
import { lazyLoad } from 'utils/loadable';
import { Divider, Paper, Typography } from '@mui/material';
import { Post } from 'types/post';
import { useParams } from 'react-router-dom';
import ContentAPI from 'api/contentAPI';
import { useDispatch } from 'react-redux';
import EnhancedTable from './PostPage/components/EnhancedTable';
import { useState } from 'react';
import { GlobalColumn } from 'app/components/globalPostCell';
export const SearchPage = lazyLoad(
  () => import('./SearchPage'),
  module => module.SearchPageContent,
);

export function SearchPageContent(props: any) {
  const [triggerOnce, setTriggerOnce] = useState<boolean>(false);
  const [posts, setPosts] = useState<Post[]>();
  const globalColumn = React.useMemo(() => [GlobalColumn], []);
  let params = useParams();
  const dispatch = useDispatch();
  React.useEffect(() => {
    setTriggerOnce(triggerOnce ? true : false);
    async function getPostByUniqueLink() {
      //console.log(params);
      const regex = /[^A-Za-z0-9 ]/g;
      const srchStr = params.srchStr ? params.srchStr : '';

      let sanitized = srchStr;
      sanitized.length > 12
        ? (sanitized = srchStr)
        : // eslint-disable-next-line no-self-assign
          (sanitized = sanitized);

      const data: Post[] = await ContentAPI.searchPosts(dispatch, sanitized);
      // console.log(selectedNav, data);
      setPosts(data);
      //console.log('UNIQUELINK DATA ===> ', data);
      //console.log(data);
    }
    getPostByUniqueLink();
    //@ts-ignore

    //setAnnouncement(findAnnouncementByID(Number(params.announcementId)));
    //console.log(findAnnouncementByID(Number(params.announcementId)));
  }, [triggerOnce, params]);
  return (
    <>
      <Helmet>
        <title>Search</title>
        <meta name="description" content="Search" />
      </Helmet>
      <Box
        sx={{
          '@media (min-width: 1330px)': {
            // p: ' 15px',
          },
          '@media (max-width: 1330px)': { textAlign: 'center' },
        }}
      >
        <Paper
          variant="outlined"
          sx={{
            // mt: '7.5px',
            p: '12px',
            mx: 1,
            '& a': {
              textDecoration: 'none',
            },
            '& .announcementHeader': {
              color: 'background.paper',
            },
            // '& .announcementHeader:hover': {
            //   color: 'text.secondary',
            // },
            backgroundColor: 'primary.main',
            '@media (max-width: 900px)': {
              textAlign: 'center',
            },
            '@media (min-width: 900px)': {},
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
            '& .list': {
              height: '900px',
            },
          }}
        >
          <Typography
            className="announcementHeader"
            sx={{ fontWeight: 500, textDecoration: 'none' }}
            variant="h4"
          >
            Search
          </Typography>
        </Paper>

        <Box
          sx={{
            '@media (min-width: 1330px)': {},
            '@media (max-width: 1330px)': {},
          }}
        >
          {posts ? (
            <EnhancedTable
              columns={globalColumn}
              data={posts}
              // setData={setData}
              // updateMyData={updateMyData}
              skipPageReset={false}
            />
          ) : null}
        </Box>
      </Box>
    </>
  );
}
