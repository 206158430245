import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import Box from '@mui/material/Box';
import { lazyLoad } from 'utils/loadable';
import { Paper, Typography } from '@mui/material';
import { Image } from 'mui-image';

export const MayorsOrganizationalChart = lazyLoad(
  () => import('./MayorsOrganizationalChart'),
  module => module.MayorsOrganizationalChartContent,
);

export function MayorsOrganizationalChartContent(props: any) {
  return (
    <>
      <Helmet>
        <title>Mayors Organizational Chart</title>
        <meta name="description" content="Mayors Organizational Chart" />
      </Helmet>
      <Box sx={{ p: '15px' }}>
        <Paper
          variant="outlined"
          sx={{
            // mt: '7.5px',
            p: '12px',
            '& a': {
              textDecoration: 'none',
            },
            '& .announcementHeader': {
              color: 'text.primary',
            },
            '& .announcementHeader:hover': {
              color: 'text.secondary',
            },
            backgroundColor: 'primary.main',
            '@media (max-width: 900px)': {
              textAlign: 'center',
            },
            '@media (min-width: 900px)': {},
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
          }}
        >
          <Typography variant="h4" sx={{ color: 'background.paper' }}>
            Mayors Organizational Chart
          </Typography>
        </Paper>
        <Image
          sx={{ mt: '15px' }}
          src={require(`../../../assets/uploads/ExecutiveOrgChart.jpg`)}
          duration={1000}
          fit="contain"
          // height="40rem"
        />
      </Box>
    </>
  );
}
