import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import Box from '@mui/material/Box';
import { lazyLoad } from 'utils/loadable';
import { Divider, Paper, Typography } from '@mui/material';
import { Image } from 'mui-image';
export const AccountingOffice = lazyLoad(
  () => import('./AccountingOffice'),
  module => module.AccountingOfficeContent,
);

export function AccountingOfficeContent(props: any) {
  return (
    <>
      <Helmet>
        <title>Accounting Office</title>
        <meta name="description" content="Accounting Office" />
      </Helmet>
      <Box sx={{ p: '15px' }}>
        <Paper
          variant="outlined"
          sx={{
            // mt: '7.5px',
            p: '12px',
            '& a': {
              textDecoration: 'none',
            },
            '& .announcementHeader': {
              color: 'text.primary',
            },
            '& .announcementHeader:hover': {
              color: 'text.secondary',
            },
            backgroundColor: 'primary.main',
            '@media (max-width: 900px)': {
              textAlign: 'center',
            },
            '@media (min-width: 900px)': {},
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
          }}
        >
          <Typography variant="h4" sx={{ color: 'background.paper' }}>Accounting Office</Typography>
        </Paper>
        <Paper
          variant="outlined"
          sx={{
            my: '15px',
            p: '15px',
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
          }}
        >
          <Typography sx={{ textAlign: 'center', fontWeight: 'bolder' }}>
            Mission Statement
          </Typography>
          <Typography sx={{ textAlign: 'justify' }}>
            To continuously strive to provide accurate financial information and
            deliver accounting services with professionalism, efficiency and
            excellence to all our clients.
          </Typography>
          <Typography sx={{ textAlign: 'center', fontWeight: 'bolder' }}>
            Vision Statement
          </Typography>
          <Typography sx={{ textAlign: 'justify' }}>
            Accounting is the department mandated by law to provide timely and
            authentic financial information to our local legislators and other
            stakeholders to guide them in their decision–making process for the
            benefit of the general public.
          </Typography>
        </Paper>
        {/* <Box sx={{ p: '15px' }}>
          {!props.noHeaderImg ? (
            <Paper variant="outlined" sx={{ my: '15px', p: '15px' }}>
              <Image
                src={require(`../../../assets/localOffice/${props.specialLink}.jpg`)}
                duration={0}
                fit="contain"
                height="40rem"
              />
            </Paper>
          ) : null}
        </Box> */}
        <Paper
          variant="outlined"
          sx={{
            // mt: '7.5px',
            p: '12px',
            '& a': {
              textDecoration: 'none',
            },
            '& .announcementHeader': {
              color: 'text.primary',
            },
            '& .announcementHeader:hover': {
              color: 'text.secondary',
            },
            backgroundColor: 'primary.main',
            '@media (max-width: 900px)': {
              textAlign: 'center',
            },
            '@media (min-width: 900px)': {},
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
          }}
        >
          <Typography variant="h4" sx={{ color: 'background.paper' }}>
            Accounting Office Contact Information
          </Typography>
        </Paper>

        <Paper
          variant="outlined"
          sx={{
            my: '15px',
            p: '15px',
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
          }}
        >
          <Typography sx={{ textAlign: 'justify' }}>
            Municipal Accounting Office
          </Typography>
          <Typography sx={{ textAlign: 'justify' }}>
            Ground Floor, Tigbauan Municipal Building, Tejero St., Barangay 9,
            Tigbauan, Iloilo
          </Typography>
          <Typography sx={{ textAlign: 'justify' }}>511-9497</Typography>
        </Paper>
      </Box>
    </>
  );
}
