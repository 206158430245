import {
  Paper,
  Typography,
  Divider,
  Button,
  Grid,
  Avatar,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useRef } from 'react';
import { Image } from 'mui-image';
import { Link as RouterLink } from 'react-router-dom';
import { GlobalReducer } from 'types/globalReducer';
import { useSelector } from 'react-redux';
import { Post } from 'types/post';
import useWindowDimensions from '../../components/useWindowDimensions';
import { SideMenuLinks } from './SideMenuLinks';
//import ReactWeather, { useOpenWeather } from 'react-open-weather';

export const SideNavRight = () => {
  const { width } = useWindowDimensions();
  const ref = useRef<any>(null);

  const postsData = useSelector((state: GlobalReducer) => {
    if (state.navData.posts.length > 5) return state.navData.posts.slice(0, 5);
    else return state.navData.posts;
  });

  //console.log(announcementsData);
  return (
    <Box sx={{ height: '100%' }}>
      {width > 900 ? (
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
          <RouterLink to="/mayors-page">
            <Paper
              variant="outlined"
              sx={{
                mb: '7.5px',
                p: 1,
                boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
              }}
            >
              <Image
                src={require(`../../../assets/icons/tigbauanMayor.png`)}
                duration={0}
                fit="contain"
              />
            </Paper>
          </RouterLink>
          <RouterLink to="/tourism">
            <Paper
              variant="outlined"
              sx={{
                mb: '7.5px',
                p: 1,
                boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
              }}
            >
              <Image
                src={require(`../../../assets/icons/tigbauanTourism.png`)}
                duration={0}
                fit="contain"
              />
            </Paper>
          </RouterLink>
          <Box
            sx={{
              display: 'flex',
              flexGrow: 1,
              flexDirection: 'column',
              '& .box': {
                boxShadow:
                  'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px',
              },
            }}
          >
           <SideMenuLinks />
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};
