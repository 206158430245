import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import Box from '@mui/material/Box';
import { lazyLoad } from 'utils/loadable';
import { Divider, Typography, Paper } from '@mui/material';
import { Image } from 'mui-image';
export const PlanningOffice = lazyLoad(
  () => import('./PlanningOffice'),
  module => module.PlanningOfficeContent,
);

export function PlanningOfficeContent(props: any) {
  return (
    <>
      <Helmet>
        <title>Planning Office</title>
        <meta name="description" content="Planning Office" />
      </Helmet>
      <Box sx={{ p: '15px' }}>
        <Paper
          variant="outlined"
          sx={{
            // mt: '7.5px',
            p: '12px',
            '& a': {
              textDecoration: 'none',
            },
            '& .announcementHeader': {
              color: 'text.primary',
            },
            '& .announcementHeader:hover': {
              color: 'text.secondary',
            },
            backgroundColor: 'primary.main',
            '@media (max-width: 900px)': {
              textAlign: 'center',
            },
            '@media (min-width: 900px)': {},
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
          }}
        >
          <Typography variant="h4" sx={{ color: 'background.paper' }}>Planning Office</Typography>
        </Paper>
        <Paper
          variant="outlined"
          sx={{
            my: '15px',
            p: '15px',
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
          }}
        >
          <Typography sx={{ textAlign: 'justify' }}>
            The Office Formulates integrated economic, social, physical, and
            development plans of the Municipal Government. It undertakes studies
            researches for the innovation of the Municipal’s plan and programs
            of development. It prepares the socio-economic profile of the
            Municipality based on data and statistics gathered up from the
            barangay and other sources
          </Typography>
        </Paper>
        {/* {!props.noHeaderImg ? (
          <Paper variant="outlined" sx={{ my: '15px', p: '15px' }}>
            <Image
              src={require(`../../../assets/localOffice/${props.specialLink}.jpg`)}
              duration={0}
              fit="contain"
              height="40rem"
            />
          </Paper>
        ) : null} */}
        <Paper
          variant="outlined"
          sx={{
            // mt: '7.5px',
            p: '12px',
            '& a': {
              textDecoration: 'none',
            },
            '& .announcementHeader': {
              color: 'text.primary',
            },
            '& .announcementHeader:hover': {
              color: 'text.secondary',
            },
            backgroundColor: 'primary.main',
            '@media (max-width: 900px)': {
              textAlign: 'center',
            },
            '@media (min-width: 900px)': {},
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
          }}
        >
          <Typography variant="h4" sx={{ color: 'background.paper' }}>MPDO Contact Information</Typography>
        </Paper>

        <Paper
          variant="outlined"
          sx={{
            my: '15px',
            p: '15px',
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
          }}
        >
          <Typography sx={{ textAlign: 'justify' }}>
            Municipal Planning and Development Office (MPDO)
          </Typography>
          <Typography sx={{ textAlign: 'justify' }}>
            2nd Floor, Tigbauan Municipal Bldg., Tejero St., Brgy. 9, Tigbauan,
            Iloilo
          </Typography>
          <Typography sx={{ textAlign: 'justify' }}>332-1139</Typography>
        </Paper>
      </Box>
    </>
  );
}
