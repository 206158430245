import moment from 'moment';
class Misc {
  dateToday(data) {
    if (data) {
      return moment(data).format('MMMM Do YYYY');
    } else {
      return moment().format('MMMM Do YYYY');
    }
  }
  parseAPIDateTime(data) {
    return moment(moment(data).format('YYYY-MM-DD HH:mm:ss')).format('LL');
  }
  parseAPIDate(data) {
    return moment(data).format('YYYY-MM-DD');
  }
  parseAPIDateToDisplay(data) {
    return moment(moment(data).format('YYYY-MM-DD')).format('MMM D YYYY');
  }

  parseAPIDateTimeToShortDate(data) {
    return moment(data).format('llll');
  }
}
export default new Misc();
