import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import Box from '@mui/material/Box';
import { lazyLoad } from 'utils/loadable';
import { Divider, Paper, Typography } from '@mui/material';
import { Image } from 'mui-image';

export const ContactUs = lazyLoad(
  () => import('./ContactUs'),
  module => module.ContactUsContent,
);

export function ContactUsContent(props: any) {
  return (
    <>
      <Helmet>
        <title>Contact Us</title>
        <meta name="description" content="Contact Us" />
      </Helmet>
      <Paper
        variant="outlined"
        sx={{
          // mt: '7.5px',
          p: '12px',
          '& a': {
            textDecoration: 'none',
          },
          '& .announcementHeader': {
            color: 'text.primary',
          },
          '& .announcementHeader:hover': {
            color: 'text.secondary',
          },
          backgroundColor: 'primary.main',
          '@media (max-width: 900px)': {
            textAlign: 'center',
          },
          '@media (min-width: 900px)': {},
          boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
        }}
      >
        <Typography variant="h3" sx={{ color: 'background.paper' }}>
          Contact Us
        </Typography>
      </Paper>
      <Box sx={{ p: '15px' }}>
      <Image
            src={require(`../../assets/officeDirectory.png`)}
            duration={1000}
            fit="contain"
            // height="40rem"
          />
      </Box>
    </>
  );
}
