import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function AccessibilityDialog(props: any) {
  const handleClickOpen = () => {
    props.setDialogOpen(true);
  };
  const handleClose = () => {
    props.setDialogOpen(false);
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.dialogOpen}
        fullWidth={true}
        maxWidth="xl"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Accessibility Statement
        </BootstrapDialogTitle>
        <DialogContent sx={{ '& p': { textAlign: 'justify' } }} dividers>
          <Typography gutterBottom>
            This website adopts the Web Content Accessibility Guidelines (WCAG
            2.0) as the accessibility standard for all its related web
            development and services. WCAG 2.0 is also an international
            standard, ISO 40500. This certifies it as a stable and referenceable
            technical standard.
          </Typography>
          <Typography gutterBottom>
            WCAG 2.0 contains 12 guidelines organized under 4 principles:
            Perceivable, Operable, Understandable, and Robust (POUR for short).
            There are testable success criteria for each guideline. Compliance
            to these criteria is measured in three levels: A, AA, or AAA. A
            guide to understanding and implementing Web Content Accessibility
            Guidelines 2.0 is available at:
            https://www.w3.org/TR/UNDERSTANDING-WCAG20/
          </Typography>
          <Typography gutterBottom>Accessibility Features</Typography>
          <Typography gutterBottom>
            Shortcut Keys Combination Activation Combination keys used for each
            browser.
          </Typography>
          <Box sx={{ px: '20px' }}>
            <Typography>
              Chrome for Linux press (Alt+Shift+shortcut_key) Chrome for Windows
              press (Alt+shortcut_key)
            </Typography>
            <Typography>For Firefox press (Alt+Shift+shortcut_key)</Typography>
            <Typography>
              For Internet Explorer press (Alt+Shift+shortcut_key) then press
              (enter) On Mac OS press (Ctrl+Opt+shortcut_key)
            </Typography>
          </Box>
          <Box sx={{ px: '20px' }}>
            <Typography>
              Accessibility Statement (Combination + 0): Statement page that
              will show the available accessibility keys.
            </Typography>
            <Typography>
              Home Page (Combination + H): Accessibility key for redirecting to
              homepage.
            </Typography>
            <Typography>
              Main Content (Combination + R): Shortcut for viewing the content
              section of the current page.
            </Typography>
            <Typography>
              FAQ (Combination + Q): Shortcut for FAQ page.
            </Typography>
            <Typography>
              Contact (Combination + C): Shortcut for contact page or form
              inquiries.
            </Typography>
            <Typography>
              Feedback (Combination + K): Shortcut for feedback page.
            </Typography>
            <Typography>
              Site Map (Combination + M): Shortcut for site map (footer agency)
              section of the page.
            </Typography>
            <Typography>
              Search (Combination + S): Shortcut for search page.
            </Typography>
          </Box>
          <Typography gutterBottom>
            Press esc, or click the close the button to close this dialog box.
          </Typography>
        </DialogContent>
        {/* <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Save changes
          </Button>
        </DialogActions> */}
      </BootstrapDialog>
    </div>
  );
}
