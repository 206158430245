import { Paper, Typography, Divider } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

import { useSelector } from 'react-redux';
import { GlobalReducer } from 'types/globalReducer';
import { Post } from 'types/post';
import { Image } from 'mui-image';
import { Link as RouterLink } from 'react-router-dom';
//import ReactWeather, { useOpenWeather } from 'react-open-weather';
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import transparencySeal from '../../../assets/transparencySeal.png';
import foiLogo from '../../../assets/foiLogo.png';
export const SideNav = props => {
  // const { data, isLoading, errorMessage } = useOpenWeather({
  //   key: '49c455283a99f50d6e13725a727724ee',
  //   lat: '10.7283',
  //   lon: '122.3788',
  //   lang: 'en',
  //   unit: 'metric', // values are (metric, standard, imperial)
  // });
  const announcementsData = useSelector((state: GlobalReducer) => {
    if (state.navData.announcements.length > 5)
      return state.navData.announcements.slice(0, 5);
    else return state.navData.announcements;
  });
  const postsData = useSelector((state: GlobalReducer) => {
    if (state.navData.posts.length > 5) return state.navData.posts.slice(0, 5);
    else return state.navData.posts;
  });
  const resolutionData = useSelector((state: GlobalReducer) => {
    if (state.navData.posts.length > 5)
      return state.navData.resolutions.slice(0, 5);
    else return state.navData.resolutions;
  });
  const executiveOrderData = useSelector((state: GlobalReducer) => {
    if (state.navData.posts.length > 5)
      return state.navData.executiveOrders.slice(0, 5);
    else return state.navData.executiveOrders;
  });
  const ordinancesData = useSelector((state: GlobalReducer) => {
    if (state.navData.posts.length > 5)
      return state.navData.ordinances.slice(0, 5);
    else return state.navData.ordinances;
  });
  return (
    <Box sx={{}}>
      <Paper
        variant="outlined"
        sx={{
          textAlign: 'center',
          mb: 2,
          boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
        }}
      >
        <RouterLink to="/transparency-seal">
          <Image
            src={transparencySeal}
            duration={2000}
            fit="contain"
            height="150px"
          />
        </RouterLink>
      </Paper>
      <Paper
        variant="outlined"
        sx={{
          textAlign: 'center',
          mb: 2,
          boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
        }}
      >
        <a
          href="https://www.foi.gov.ph/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Image src={foiLogo} duration={2000} fit="contain" height="150px" />
        </a>
      </Paper>

      <Paper
        variant="outlined"
        sx={{
          pb: '15px',
          mb: 2,
          boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
        }}
      >
        <Box sx={{ backgroundColor: 'primary.main', py: 1 }}>
          <Typography
            variant="h6"
            sx={{ textAlign: 'center', color: 'background.paper' }}
          >
            Contact Information
          </Typography>
        </Box>
        <Box sx={{ px: 2 }}>
          <Typography variant="h6">Address</Typography>
          <Typography sx={{}}>
            Tejero street 5021 Tigbauan, Philippines
          </Typography>
          <Divider sx={{ my: '7.5px' }} />
          <Typography variant="h6">Telephone</Typography>
          <Typography sx={{}}>(033) 321 2474</Typography>
          <Divider sx={{ my: '7.5px' }} />
          <Typography variant="h6">Email</Typography>
          <Typography>vneneteruel.lgu@gmail.com</Typography>
        </Box>
      </Paper>
      <Box>
        {/* {process.env.NODE_ENV === 'production' ? (
          <ReactWeather
            isLoading={isLoading}
            errorMessage={errorMessage}
            data={data}
            lang="en"
            locationLabel="Tigbauan, Iloilo"
            unitsLabels={{ temperature: 'C', windSpeed: 'Km/h' }}
            showForecast
          />
        ) : null} */}

        <Paper
          variant="outlined"
          sx={{
            width: '100%',
            mb: 2,
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
          }}
        >
          <Box
            sx={{
              backgroundColor: 'primary.main',
              py: 1,
              color: 'background.paper',
            }}
          >
            <Typography variant="h6" sx={{ textAlign: 'center' }}>
              Tigbauan Location
            </Typography>
          </Box>
          <Box sx={{ p: '1px' }}>
            <iframe
              title="Tigbauan Google Map"
              width="100%"
              height="300"
              style={{
                borderBottomLeftRadius: '5px',
                borderBottomRightRadius: '5px',
              }}
              src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Tigbauan+(Tigbauan)&amp;t=&amp;z=11&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            ></iframe>
          </Box>
        </Paper>
        <Paper
          variant="outlined"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            '@media (max-width: 1180px)': {
              // px: 5,
            },
            boxShadow:
              'rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px',
          }}
        >
           <Box
            sx={{
              backgroundColor: 'primary.main',
              py: 1,
              color: 'background.paper',
            }}
          >
            <Typography variant="h6" sx={{ textAlign: 'center' }}>
             Iloilo Province Facebook
            </Typography>
          </Box>
          <Box
            sx={{
              display:'flex',
              justifyContent:'center',
              width: '100%',
              py: 1,
            }}
          >
            <iframe
              title="fb:page Facebook Social Plugin"
              src={`https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fbalita.kapitolyo%2F&tabs=timeline&width=266&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=200642523304339`}
              width={240}
              height="500"
              style={{
                border: 'none',
                overflow: 'hidden !important',
                // maxWidth: '80vw !important',
              }}
              allow="encrypted-media"
            ></iframe>
          </Box>
        </Paper>
        {/* <Paper
          variant="outlined"
          sx={{ mb: 2, boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}
        >
          <Box sx={{ backgroundColor: 'primary.main', py: 1 }}>
            <Typography
              variant="h6"
              sx={{ textAlign: 'center', color: 'background.paper' }}
            >
              Tigbauan Hymn
            </Typography>
          </Box>

          <AudioPlayer
            style={{ borderRadius: '0px' }}
            src={require('../../../assets/tigbauanHymn.mp3')}
            showJumpControls={false}
            showSkipControls={false}
            customControlsSection={[
              RHAP_UI.MAIN_CONTROLS,
              RHAP_UI.VOLUME_CONTROLS,
            ]}
            customProgressBarSection={[
              RHAP_UI.CURRENT_TIME,
              RHAP_UI.PROGRESS_BAR,
              RHAP_UI.DURATION,
            ]}
            // other props here
          />
        </Paper> */}
        {/* <Paper
          variant="outlined"
          sx={{
            p: '7.5px',
            mb: 2,
            '& a': {
              textDecoration: 'none',
            },
            '& .postTitleLink': {
              color: '#2ba6cb !important',
              my: '5px',
            },
            '& .postTitleLink:hover': { color: '#258faf !important' },
          }}
        >
          <Typography variant="h5" sx={{ my: '7.5px', textAlign: 'center' }}>
            Announcements
          </Typography>

          <Divider />
          {announcementsData.map((value: Post, index) => {
            return (
              <Box key={index}>
                <RouterLink to={`/posts/${value.id}`}>
                  <Typography key={index + 'p'} className="postTitleLink">
                    {value.title}
                  </Typography>
                </RouterLink>
                <Divider />
              </Box>
            );
          })}
        </Paper> */}
        {/* <Paper
          variant="outlined"
          sx={{
            // px: '7.5px',
            mb: 2,
            '& a': {
              textDecoration: 'none',
            },
            '& .postTitleLink': {
              color: '#2ba6cb !important',
              // my: '5px',
            },
            '& .postTitleLink:hover': { color: '#258faf !important' },
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
          }}
        >
          <Box sx={{ backgroundColor: 'primary.main', py: 1 }}>
            <Typography
              variant="h6"
              sx={{ textAlign: 'center', color: 'background.paper' }}
            >
              Announcements
            </Typography>
          </Box>
          <Box sx={{ p: '7.5px' }}>
            {announcementsData.map((value: Post, index) => {
              return (
                <Box key={index + 'pbx'}>
                  <RouterLink to={`/posts/${value.id}`}>
                    <Typography key={index + 'p'} className="postTitleLink">
                      {value.title}
                    </Typography>
                  </RouterLink>
                  <Divider sx={{ mb: '8px' }} />
                </Box>
              );
            })}
          </Box>
        </Paper>
        <Paper
          variant="outlined"
          sx={{
            // px: '7.5px',

            mb: 2,
            '& a': {
              textDecoration: 'none',
            },
            '& .postTitleLink': {
              color: '#2ba6cb !important',
              // my: '5px',
            },
            '& .postTitleLink:hover': { color: '#258faf !important' },
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
          }}
        >
          <Box sx={{ backgroundColor: 'primary.main', py: 1,  }}>
            <Typography
              variant="h6"
              sx={{ textAlign: 'center', color: 'background.paper' }}
            >
              Latest Post
            </Typography>
          </Box>
          <Box sx={{ p: '7.5px' }}>
            {postsData.map((value: Post, index) => {
              return (
                <Box key={index}>
                  <RouterLink to={`/posts/${value.id}`}>
                    <Typography key={index + 'p'} className="postTitleLink">
                      {value.title}
                    </Typography>
                  </RouterLink>
                  <Divider sx={{ mb: '8px' }} />
                </Box>
              );
            })}
          </Box>
        </Paper> */}
        {/* <Paper
          variant="outlined"
          sx={{
            // px: '7.5px',
            mb: 2,
            '& a': {
              textDecoration: 'none',
            },
            '& .postTitleLink': {
              color: '#2ba6cb !important',
              // my: '5px',
            },
            '& .postTitleLink:hover': { color: '#258faf !important' },
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
          }}
        >
          <Box sx={{ backgroundColor: 'primary.main', py: 1 }}>
            <Typography
              variant="h6"
              sx={{ textAlign: 'center', color: 'background.paper' }}
            >
              Executive Orders
            </Typography>
          </Box>
          <Box sx={{ p: '7.5px' }}>
            {executiveOrderData.map((value: Post, index) => {
              return (
                <Box key={index}>
                  <RouterLink to={`/posts/${value.id}`}>
                    <Typography key={index + 'p'} className="postTitleLink">
                      {value.title}
                    </Typography>
                  </RouterLink>
                  <Divider sx={{ mb: '8px' }} />
                </Box>
              );
            })}
          </Box>
        </Paper> */}
        {/* <Paper
          variant="outlined"
          sx={{
            // px: '7.5px',
            mb: 2,
            '& a': {
              textDecoration: 'none',
            },
            '& .postTitleLink': {
              color: '#2ba6cb !important',
              // my: '5px',
            },
            '& .postTitleLink:hover': { color: '#258faf !important' },
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
          }}
        >
          <Box sx={{ backgroundColor: 'primary.main', py: 1 }}>
            <Typography
              variant="h6"
              sx={{ textAlign: 'center', color: 'background.paper' }}
            >
              Resolutions
            </Typography>
          </Box>
          <Box sx={{ p: '7.5px' }}>
            {resolutionData.map((value: Post, index) => {
              return (
                <Box key={index}>
                  <RouterLink to={`/posts/${value.id}`}>
                    <Typography key={index + 'p'} className="postTitleLink">
                      {value.title}
                    </Typography>
                  </RouterLink>
                  <Divider sx={{ mb: '8px' }} />
                </Box>
              );
            })}
          </Box>
        </Paper> */}
        {/* <Paper
          variant="outlined"
          sx={{
            // px: '7.5px',
            mb: 2,
            '& a': {
              textDecoration: 'none',
            },
            '& .postTitleLink': {
              color: '#2ba6cb !important',
              // my: '5px',
            },
            '& .postTitleLink:hover': { color: '#258faf !important' },
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
          }}
        >
          <Box sx={{ backgroundColor: 'primary.main', py: 1 }}>
            <Typography
              variant="h6"
              sx={{ textAlign: 'center', color: 'background.paper' }}
            >
              Ordinances
            </Typography>
          </Box>
          <Box sx={{ p: '7.5px' }}>
            {ordinancesData.map((value: Post, index) => {
              return (
                <Box key={index}>
                  <RouterLink to={`/posts/${value.id}`}>
                    <Typography key={index + 'p'} className="postTitleLink">
                      {value.title}
                    </Typography>
                  </RouterLink>
                  <Divider sx={{ mb: '8px' }} />
                </Box>
              );
            })}
          </Box>
        </Paper> */}
      </Box>
    </Box>
  );
};
