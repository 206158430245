import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import Box from '@mui/material/Box';
import { lazyLoad } from 'utils/loadable';
import { Paper, Typography } from '@mui/material';

export const TigbauanFestival = lazyLoad(
  () => import('./TigbauanFestival'),
  module => module.TigbauanFestivalContent,
);

export function TigbauanFestivalContent(props: any) {
  return (
    <>
      <Helmet>
        <title>Tigbauan Festival</title>
        <meta name="description" content="Tigbauan Festival" />
      </Helmet>
      <Box sx={{ p: '15px' }}>
        <Paper
          variant="outlined"
          sx={{
            // mt: '7.5px',
            p: '12px',
            '& a': {
              textDecoration: 'none',
            },
            '& .announcementHeader': {
              color: 'text.primary',
            },
            '& .announcementHeader:hover': {
              color: 'text.secondary',
            },
            backgroundColor: 'primary.main',
            '@media (max-width: 900px)': {
              textAlign: 'center',
            },
            '@media (min-width: 900px)': {},
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
          }}
        >
          <Typography variant="h4" sx={{ color: 'background.paper' }}>Tigbauan Festival</Typography>
        </Paper>
      </Box>
    </>
  );
}
