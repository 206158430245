import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import Box from '@mui/material/Box';
import { lazyLoad } from 'utils/loadable';
import { Divider, Typography, Paper } from '@mui/material';
import { Image } from 'mui-image';
export const EngineeringOffice = lazyLoad(
  () => import('./EngineeringOffice'),
  module => module.EngineeringOfficeContent,
);

export function EngineeringOfficeContent(props: any) {
  return (
    <>
      <Helmet>
        <title>Engineering Office</title>
        <meta name="description" content="Engineering Office" />
      </Helmet>
      <Box sx={{ p: '15px' }}>
        <Paper
          variant="outlined"
          sx={{
            // mt: '7.5px',
            p: '12px',
            '& a': {
              textDecoration: 'none',
            },
            '& .announcementHeader': {
              color: 'text.primary',
            },
            '& .announcementHeader:hover': {
              color: 'text.secondary',
            },
            backgroundColor: 'primary.main',
            '@media (max-width: 900px)': {
              textAlign: 'center',
            },
            '@media (min-width: 900px)': {},
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
          }}
        >
          <Typography variant="h4" sx={{ color: 'background.paper' }}>Engineering Office</Typography>
        </Paper>
        <Paper
          variant="outlined"
          sx={{
            my: '15px',
            p: '15px',
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
          }}
        >
          <Typography sx={{ textAlign: 'justify' }}>
            The Office is in charge of the LGU infrastructure and public works.
            Aside from the engineering services, it also conducts surveys,
            technical supervision and management that redound to the proper
            implementation of projects of the LGU. This office is also in-charge
            in the implementation of the national building code (PD 1096).
          </Typography>
        </Paper>
        {/* {!props.noHeaderImg ? (
          <Paper variant="outlined" sx={{ my: '15px', p: '15px' }}>
            <Image
              src={require(`../../../assets/localOffice/${props.specialLink}.jpg`)}
              duration={0}
              fit="contain"
              height="40rem"
            />
          </Paper>
        ) : null} */}
        <Paper
          variant="outlined"
          sx={{
            // mt: '7.5px',
            p: '12px',
            '& a': {
              textDecoration: 'none',
            },
            '& .announcementHeader': {
              color: 'text.primary',
            },
            '& .announcementHeader:hover': {
              color: 'text.secondary',
            },
            backgroundColor: 'primary.main',
            '@media (max-width: 900px)': {
              textAlign: 'center',
            },
            '@media (min-width: 900px)': {},
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
          }}
        >
          <Typography variant="h4" sx={{ color: 'background.paper' }}>
            Engineering Office Contact Information
          </Typography>
        </Paper>
        <Paper
          variant="outlined"
          sx={{
            my: '15px',
            p: '15px',
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
          }}
        >
          <Typography sx={{ textAlign: 'justify' }}>
            Municipal Engineering Office
          </Typography>
          <Typography sx={{ textAlign: 'justify' }}>
            2nd Floor, Tigbauan Municipal Bldg., Tejero St., Brgy. 9, Tigbauan,
            Iloilo
          </Typography>
          <Typography sx={{ textAlign: 'justify' }}>336-1344</Typography>
        </Paper>
      </Box>
    </>
  );
}
