import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import Box from '@mui/material/Box';
import { lazyLoad } from 'utils/loadable';
import { Divider, Typography, Paper } from '@mui/material';
import { Image } from 'mui-image';
export const TreasurersOffice = lazyLoad(
  () => import('./TreasurersOffice'),
  module => module.TreasurersOfficeContent,
);

export function TreasurersOfficeContent(props: any) {
  return (
    <>
      <Helmet>
        <title>Treasurers Office</title>
        <meta name="description" content="Treasurers Office" />
      </Helmet>
      <Box sx={{ p: '15px' }}>
        <Paper
          variant="outlined"
          sx={{
            // mt: '7.5px',
            p: '12px',
            '& a': {
              textDecoration: 'none',
            },
            '& .announcementHeader': {
              color: 'text.primary',
            },
            '& .announcementHeader:hover': {
              color: 'text.secondary',
            },
            backgroundColor: 'primary.main',
            '@media (max-width: 900px)': {
              textAlign: 'center',
            },
            '@media (min-width: 900px)': {},
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
          }}
        >
          <Typography variant="h4" sx={{ color: 'background.paper' }}>Treasurers Office</Typography>
        </Paper>
        <Paper
          variant="outlined"
          sx={{
            my: '15px',
            p: '15px',
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
          }}
        >
          <Typography sx={{ textAlign: 'justify' }}>
            The Office of the Municipal Treasurer takes custody and management
            of the Municipal Government funds and all others that may be
            entrusted by law or competent authority.
          </Typography>
        </Paper>
        {/* {!props.noHeaderImg ? (
          <Paper variant="outlined" sx={{ my: '15px', p: '15px' }}>
            <Image
              src={require(`../../../assets/localOffice/${props.specialLink}.jpg`)}
              duration={0}
              fit="contain"
              height="40rem"
            />
          </Paper>
        ) : null} */}
        <Paper
          variant="outlined"
          sx={{
            // mt: '7.5px',
            p: '12px',
            '& a': {
              textDecoration: 'none',
            },
            '& .announcementHeader': {
              color: 'text.primary',
            },
            '& .announcementHeader:hover': {
              color: 'text.secondary',
            },
            backgroundColor: 'primary.main',
            '@media (max-width: 900px)': {
              textAlign: 'center',
            },
            '@media (min-width: 900px)': {},
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
          }}
        >
          <Typography variant="h4" sx={{ color: 'background.paper' }}>
            Treasurer’s Office Contact Information
          </Typography>
        </Paper>

        <Paper
          variant="outlined"
          sx={{
            my: '15px',
            p: '15px',
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
          }}
        >
          <Typography sx={{ textAlign: 'justify' }}>
            Municipal Treasurer’s Office
          </Typography>
          <Typography sx={{ textAlign: 'justify' }}>
            Ground Floor, Tigbauan Municipal Bldg., Tejero St., Brgy. 9,
            Tigbauan, Iloilo
          </Typography>
          <Typography sx={{ textAlign: 'justify' }}>511-7935</Typography>
        </Paper>
      </Box>
    </>
  );
}
