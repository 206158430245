import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Image } from 'mui-image';
import {
  Paper,
  Typography,
  Chip,
  Divider,
  Grid,
  Button,
  Link as MUILink,
  Avatar,
  Fab,
  MenuItem,
  Menu,
  Popper,
  Fade,
  ClickAwayListener,
} from '@mui/material';
import { Box } from '@mui/system';
import {
  apiSetVisitorInteractionsId,
  uiToggleDocViewer,
} from 'actions/actionCreator';
import ReactQuill from 'react-quill';
import { useDispatch, useSelector } from 'react-redux';
import { Post } from 'types/post';
import Misc from '../../functions/misc';
import useWindowDimensions from './useWindowDimensions';
import { useRef } from 'react';

import TigbauanLogo from '../../assets/tigbauanLogoCircle.png';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import { Facebook, Visibility } from '@mui/icons-material';
import { enqueueSnackbar } from 'notistack';
import contentAPI from 'api/contentAPI';
import { GlobalReducer, IVisitorData } from 'types/globalReducer';
import { TlinkNames } from 'types/links';
export const GlobalColumn = {
  Header: 'Post',
  accessor: 'name',

  Cell: data => {
    const dispatch = useDispatch();
    const { width } = useWindowDimensions();
    const quillElement = useRef(null);

    // console.log(data.cell.row.original.postContent);
    // console.log(htmlToDraft(data.cell.row.original.postContent));
    //const contentBlock = htmlToDraft(data.cell.row.original.postContent);
    //let contentState: any = EditorState.createEmpty();
    //contentState = data.cell.row.original.postContent;
    const cell: Post = data.cell.row.original;
    const files = cell.files !== '' ? JSON.parse(cell.files) : null;

    const hasVideo = cell.postContent.includes('iframe');

    // const postContent =
    //   cell.postContent !== null ? JSON.parse(cell.postContent) : null;
    // console.log(postContent);

    const [limitWidth, setLimitWidth] = React.useState<boolean>(false);
    const [postHeight, setPostHeight] = React.useState<number>(350);

    const [anchorLike, setAnchorLike] = React.useState<null | HTMLElement>(
      null,
    );
    const [anchorShare, setAnchorShare] = React.useState<null | HTMLElement>(
      null,
    );

    const [postLikeCount, setPostLikeCount] = React.useState<number>(
      cell.likesCount ? cell.likesCount : 0,
    );
    const [postShareCount, setPostShareCount] = React.useState<number>(
      cell.sharesCount ? cell.sharesCount : 0,
    );
    const [postViewsCount, setPostViewsCount] = React.useState<number>(
      cell.viewsCount ? cell.viewsCount : 0,
    );

    const VisitorData: IVisitorData = useSelector(
      (state: GlobalReducer) => state.navData.visitorData,
    );
    const VisitorInteractions: number[] = useSelector(
      (state: GlobalReducer) => state.navData.userPostInteractionsId,
    );

    React.useEffect(() => {
      if (quillElement.current) {
        // @ts-ignore
        //console.log(quillElement.current.getEditor().root.clientHeight);

        setPostHeight(
          //@ts-ignore
          Number(quillElement.current.getEditor().root.clientHeight) + 120,
        );
        //@ts-ignore
        if (quillElement.current.getEditor().editor.delta.ops.length > 20) {
          setLimitWidth(true);
        }
      }
    }, [quillElement.current]);

    const WithImagePost = files.length > 0 ? true : false;

    return (
      <Box
        // elevation={3}
        sx={{
          '& a': {
            textDecoration: 'none',
          },
          '& .postTitleLink': {
            color: 'secondary.main',
            textTransform: 'uppercase',
            fontWeight: '600',
          },
          '& .postTitleLink:hover': { color: 'primary.main' },
          // p: '15px',

          '@media (max-width: 500px)': {
            mx: 0,
          },
          '@media (min-width: 501px)': {
            mx: 1,
            '& .postTitleLink': {
              fontSize: '1.5rem',

            },
          },
          // borderRadius: '5px',
          boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
          // minHeight: '550px',
          backgroundColor: 'background.paper',

        }}
      >
        <Grid container>
          <Grid item xs={12} sx={{ p:3 }}>
            {/* <Divider orientation="vertical" flexItem /> */}

            {/* {cell.headerLink ? (
              <>
                <MUILink
                  variant="h6"
                  className="postTitleLink"
                  href={`//${cell.headerLink}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    //my: '15px',
                    py: 1,
                    lineHeight: '1.3rem',
                    '@media (max-width: 600px)': {
                      textAlign: 'center',
                      fontSize: '1.3rem',
                    },
                  }}
                >
                  {cell.title}
                </MUILink>
              </>
            ) : (

            )} */}
            <RouterLink to={`../${cell.uniqueLink}/${cell.id}`}>
              <Typography
                variant="h6"
                className="postTitleLink"
                sx={{
                  //my: '15px',
                  //my: '15px',
                  py: 1,
                  lineHeight: '1.3rem',
                  '@media (max-width: 600px)': {
                    textAlign: 'center',
                    fontSize: '1.3rem',
                  },
                }}
              >
                {cell.title}
              </Typography>
            </RouterLink>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                py: 1,
              }}
            >
              {/* <Avatar
                sx={{ width: 24, height: 24, mr: 1 }}
                alt="user-avatar"
                src={TigbauanLogo}
              /> */}
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Typography
                  sx={{
                    fontWeight: '300',
                    m: 0,
                    p: 0,
                    lineHeight: '1rem',
                    fontSiz: '0.6rem',
                  }}
                >
                  Posted on {Misc.parseAPIDateTime(cell.datePosted)}
                </Typography>

                {/* <Typography
                  sx={{ fontWeight: '300', m: 0, p: 0, lineHeight: '1rem' }}
                >

                </Typography> */}
                {/* <Typography
                  sx={{ fontWeight: '300', m: 0, p: 0, lineHeight: '1rem' }}
                >
                  {cell.postOwner !== null ? cell.postOwner.name : 'Admin'}
                </Typography> */}
              </Box>
            </Box>

            {/* <Divider sx={{ my: '7.5px' }} /> */}
          </Grid>
          {/* <Grid
            item
            xs={12}
            md={
              WithImagePost
                ? cell.postContent && cell.postContent.length > 1
                  ? 6
                  : 12
                : 0
            }
            lg={
              WithImagePost
                ? cell.postContent && cell.postContent.length > 1
                  ? 6
                  : 12
                : 0
            }
            xl={
              WithImagePost
                ? cell.postContent && cell.postContent.length > 1
                  ? 6
                  : 12
                : 0
            }
            sx={{}}
          >
            {files.length > 0 ? (
              <Image
                src={`${process.env.REACT_APP_API_URL}global/getFile?uniqueId=${
                  cell.uniqueId
                }&filename=${encodeURIComponent(
                  files[0].filename,
                )}&token=${localStorage.getItem('LUT')}`}
                duration={0}
                fit="scale-down"
                height="550px"
              />
            ) : null}
          </Grid>

          {cell.postContent && cell.postContent.length > 0 && (
            <Grid
              item
              xs={12}
              md={WithImagePost ? 6 : 12}
              lg={WithImagePost ? 6 : 12}
              xl={WithImagePost ? 6 : 12}
              sx={{ px: 3, py: 3 }}
            >
              <Box
                sx={{
                  // pl: cell.postBanner !== '' ? '15px' : '0px',
                  // maxWidth: '10px !important',
                  '& .ql-editor .ql-container': {
                    border: 'none',
                    maxHeight: limitWidth ? '300px' : 'unset',
                  },
                  '& .ql-container': {
                    border: 'none',
                    maxHeight: limitWidth ? '300px' : 'unset',
                    display: 'flex',
                    placeContent: 'center',
                    overflow: 'hidden',
                  },
                  '& .ql-editor': {
                    overflow: 'hidden',

                    border: 'none',
                    maxHeight: limitWidth ? '300px' : 'unset',
                    scrollbarWidth: 'none',
                    '-ms-overflow-style': 'none',
                    '@media (max-width: 900px)': {
                      width: width - 100,
                    },
                  },
                  '& .ql-editor p': {
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    textAlign: 'justify',
                    display: '-webkit-box',
                    '-webkit-line-clamp': '18',
                    '-webkit-box-orient': 'vertical',
                  },
                  '& .ql-editor > p > span': {
                    backgroundColor: 'transparent !important',
                  },
                  '& .quill ': {
                    height: hasVideo ? 550 : 365,
                  },
                  '& .ql-editor::-webkit-scrollbar': {
                    display: 'none',
                  },
                }}
              >
                <Box sx={{ position: 'relative' }}>
                  {limitWidth ? (
                    <Box
                      sx={{
                        position: 'absolute',
                        height: '100%',
                        width: '100%',
                        zIndex: 1,
                        backgroundColor: 'transparent',
                      }}
                    ></Box>
                  ) : null}
                  <Box
                    sx={{
                      position: 'relative',
                      '& .quill iframe': {
                        // pointerEvents: 'none',
                        height: `${postHeight}px`,
                      },
                    }}
                  >
                    <ReactQuill
                      modules={{ toolbar: false }}
                      theme="snow"
                      scrollingContainer="html"
                      readOnly
                      defaultValue={cell.postContent}
                      ref={quillElement}
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>
          )} */}
          {/* <Grid item xs={12} sx={{ pb: 3, px: 3 }}>
            <Divider sx={{ mb: '15px' }} />
            <Box display={'flex'} justifyContent={'end'}>
              <Box display={'flex'} sx={{ mx: 2 }}>
                <Fab
                  sx={{ width: 30, height: 30, minHeight: 'unset' }}
                  aria-label="like"
                  onClick={e => {
                    //setAnchorLike(e.currentTarget);
                  }}
                >
                  <Visibility sx={{ width: 20, height: 20 }} />
                </Fab>
                <Typography alignSelf={'center'} sx={{ pl: 1 }}>
                  {postViewsCount}
                </Typography>
              </Box>
              <Box display={'flex'} sx={{ mx: 2 }}>
                <Fab
                  sx={{ width: 30, height: 30, minHeight: 'unset' }}
                  aria-label="like"
                  onClick={async e => {
                    setAnchorLike(e.currentTarget);
                    await contentAPI.likePost(
                      dispatch,
                      String(cell.id),
                      VisitorData.uniqueKey,
                    );

                    //Refresh data to update like button
                    let _userInteractions =
                      await contentAPI.getVisitorInteractions(
                        dispatch,
                        VisitorData.uniqueKey,
                      );
                    //console.log(_userInteractions);
                    dispatch(apiSetVisitorInteractionsId(_userInteractions));
                    setPostLikeCount(x =>
                      VisitorInteractions.includes(Number(cell.id)) ? --x : ++x,
                    );
                  }}
                >
                  <FavoriteIcon
                    sx={{
                      width: 20,
                      height: 20,
                      color:
                        VisitorInteractions &&
                        VisitorInteractions.includes(Number(cell.id))
                          ? 'red'
                          : 'black',
                    }}
                  />
                </Fab>
                <Typography alignSelf={'center'} sx={{ pl: 1 }}>
                  {postLikeCount}
                </Typography>
              </Box>
              <Box display={'flex'}>
                <Fab
                  sx={{ width: 30, height: 30, minHeight: 'unset' }}
                  aria-label="share"
                  onClick={e => {
                    setAnchorShare(e.currentTarget);
                  }}
                >
                  <ShareIcon sx={{ width: 20, height: 20 }} />
                </Fab>
                {Boolean(anchorShare) && (
                  <ClickAwayListener
                    onClickAway={() => {
                      setAnchorShare(null);
                    }}
                  >
                    <Popper
                      id={Boolean(anchorShare) ? 'simple-popper' : undefined}
                      open={Boolean(anchorShare)}
                      anchorEl={anchorShare}
                      transition
                    >
                      {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={350}>
                          <Paper
                            sx={{
                              p: 1,
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'flex-start',
                            }}
                          >
                            <Button
                              startIcon={<ShareIcon sx={{ color: 'black' }} />}
                              onClick={() => {
                                setAnchorShare(null);
                                navigator.clipboard.writeText(
                                  `https://tigbauan.gov.ph/`,
                                );
                                enqueueSnackbar('Link Copied.', {
                                  variant: 'success',
                                });
                              }}
                              sx={{
                                color: 'black',
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'start',
                              }}
                            >
                              Copy Link
                            </Button>
                            <Button
                              startIcon={<Facebook sx={{ color: 'black' }} />}
                              onClick={() => {
                                window.open(
                                  'https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Ftigbauan.gov.ph%2F&amp;src=sdkpreparse',
                                  'targetWindow',
                                  'toolbar=no,location=0,status=no,menubar=no,scrollbars=yes,resizable=yes,width=600,height=250',
                                );
                                setAnchorShare(null);
                                return false;
                              }}
                              className="fb-xfbml-parse-ignore"
                              data-href="https://tigbauan.gov.ph/"
                              data-layout=""
                              data-size=""
                              href="/sharer/sharer.php?kid_directed_site=0&amp;sdk=joey&amp;u=https%3A%2F%2Ftigbauan.gov.ph%2F&amp;display=popup&amp;ref=plugin&amp;src=share_button"
                              target="_blank"
                              //rel="noopener noreferrer"
                              sx={{ color: 'black', width: '100%' }}
                            >
                              Share to Facebook
                            </Button>
                          </Paper>
                        </Fade>
                      )}
                    </Popper>
                  </ClickAwayListener>
                )}


              </Box>
            </Box>
          </Grid> */}
        </Grid>
        {/* //LEGACY */}
        {/* {(() => {
          if (cell.isImageOnly === 0) {
            return (
              <>
                {cell.headerLink ? (
                  <>
                    <MUILink
                      variant="h4"
                      className="postTitleLink"
                      href={`//${cell.headerLink}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{
                        py: '15px',
                        '@media (max-width: 600px)': {
                          textAlign: 'center',
                          fontSize: '1.3rem',
                        },
                      }}
                    >
                      {cell.title}
                    </MUILink>
                  </>
                ) : (
                  <RouterLink to={`../${cell.uniqueLink}/${cell.id}`}>
                    <Typography
                      variant="h4"
                      className="postTitleLink"
                      sx={{
                        py: '15px',
                        '@media (max-width: 600px)': {
                          textAlign: 'center',
                          fontSize: '1.3rem',
                        },
                      }}
                    >
                      {cell.title}
                    </Typography>
                  </RouterLink>
                )}

                <Box sx={{ mb: '7.5px' }}>
                  {cell.isAnnouncement === 1 ? (
                    <Chip
                      sx={{
                        mx: '2px',
                        backgroundColor: 'error.light',
                        color: 'white',
                      }}
                      label="Announcement"
                      size="small"
                    />
                  ) : null}
                  {limitWidth ? (
                    <Chip
                      sx={{
                        mx: '2px',
                        backgroundColor: 'info.light',
                        color: 'white',
                      }}
                      label="Long Post"
                      size="small"
                    />
                  ) : null}
                </Box>
                <Divider sx={{ mb: '15px' }} />
                <Grid container>
                  {cell.postBanner !== '' && files.length > 0 ? (
                    <Grid
                      item
                      sx={{ textAlign: 'center', p: 5 }}
                      xs={cell.postBanner !== '' ? 12 : 0}
                    >
                      <Image
                        src={`${
                          process.env.REACT_APP_API_URL
                        }global/getFile?uniqueId=${
                          cell.uniqueId
                        }&filename=${encodeURIComponent(
                          files[0].filename,
                        )}&token=${localStorage.getItem('LUT')}`}
                        duration={0}
                        fit="contain"
                        width="100%"
                        height="100%"
                        // height="300px"
                      />
                      <Button
                        disableElevation
                        sx={{ boxShadow: 'none' }}
                        variant="contained"
                        onClick={() => {
                          dispatch(
                            uiToggleDocViewer(
                              true,
                              files[0].filename,
                              cell.uniqueId,
                            ),
                          );
                        }}
                      >
                        Enlarge Image
                      </Button>
                    </Grid>
                  ) : null}

                  <Grid
                    sx={{
                      // pl: cell.postBanner !== '' ? '15px' : '0px',
                      // maxWidth: '10px !important',
                      '& .ql-editor .ql-container': {
                        border: 'none',
                        maxHeight: limitWidth ? '300px' : 'unset',
                      },
                      '& .ql-container': {
                        border: 'none',
                        maxHeight: limitWidth ? '300px' : 'unset',
                        display: 'flex',
                        placeContent: 'center',
                      },
                      '& .ql-editor': {
                        overFlow: 'hidden',

                        border: 'none',
                        maxHeight: limitWidth ? '300px' : 'unset',
                        scrollbarWidth: 'none',
                        '-ms-overflow-style': 'none',
                        '@media (max-width: 900px)': {
                          width: width - 100,
                        },
                      },
                      '& .ql-editor::-webkit-scrollbar': {
                        display: 'none',
                      },
                    }}
                    item
                    xs={12}
                    sm={12}
                  >
                    <Box sx={{ position: 'relative' }}>
                      {limitWidth ? (
                        <Box
                          sx={{
                            position: 'absolute',
                            height: '100%',
                            width: '100%',
                            zIndex: 1,
                            backgroundColor: 'transparent',
                          }}
                        ></Box>
                      ) : null}
                      <Box
                        sx={{
                          position: 'relative',
                          '& .quill iframe': {
                            // pointerEvents: 'none',
                            height: `${postHeight}px`,
                          },
                        }}
                      >
                        <ReactQuill
                          modules={{ toolbar: false }}
                          theme="snow"
                          scrollingContainer="html"
                          readOnly
                          defaultValue={cell.postContent}
                          ref={quillElement}
                        />
                      </Box>
                    </Box>


                  </Grid>
                </Grid>
              </>
            );
          } else {
            if (cell.imageLink !== null && cell.imageLink !== '') {
              return (
                <Box>
                  <Box sx={{ mb: '7.5px' }}>
                    {cell.isAnnouncement === 1 ? (
                      <Chip
                        sx={{
                          mx: '2px',
                          backgroundColor: 'error.light',
                          color: 'white',
                        }}
                        label="Announcement"
                        size="small"
                      />
                    ) : null}
                  </Box>
                  <Box sx={{ mb: '7.5px' }}>
                    {cell.isImageOnly === 1 ? (
                      <Chip
                        sx={{
                          mx: '2px',
                          backgroundColor: 'info.main',
                          color: 'white',
                        }}
                        label="Clickable Image"
                        size="small"
                      />
                    ) : null}
                  </Box>
                  <Divider sx={{ mb: '15px' }} />
                  <Box sx={{ textAlign: 'center' }}>
                    <a
                      href={`//${cell.imageLink}`}
                      target="_blank"
                      rel="noopener noreferrer"

                      // variant="contained"
                    >
                      {files.length > 0 ? (
                        <Grid item xs={cell.postBanner !== null ? 12 : 0}>
                          <Image
                            src={`${
                              process.env.REACT_APP_API_URL
                            }global/getFile?uniqueId=${
                              cell.uniqueId
                            }&filename=${encodeURIComponent(
                              files[0].filename,
                            )}&token=${localStorage.getItem('LUT')}`}
                            duration={0}
                            fit="cover"
                            // height="500px"
                          />
                        </Grid>
                      ) : null}
                    </a>
                  </Box>
                </Box>
              );
            } else {
              return (
                <>
                  <Box sx={{ mb: '7.5px' }}>
                    {cell.isAnnouncement === 1 ? (
                      <Chip
                        sx={{
                          mx: '2px',
                          backgroundColor: 'error.light',
                          color: 'white',
                        }}
                        label="Announcement"
                        size="small"
                      />
                    ) : null}
                  </Box>
                  <Grid container sx={{ textAlign: 'center' }}>
                    {files.length > 0 ? (
                      <Grid item xs={cell.postBanner !== null ? 12 : 0}>
                        <Image
                          src={`${
                            process.env.REACT_APP_API_URL
                          }global/getFile?uniqueId=${
                            cell.uniqueId
                          }&filename=${encodeURIComponent(
                            files[0].filename,
                          )}&token=${localStorage.getItem('LUT')}`}
                          duration={0}
                          fit="cover"
                          width="100%"
                          height="100%"
                          // height="500px"
                        />
                      </Grid>
                    ) : null}
                  </Grid>
                </>
              );
            }
          }
        })()}
        <Divider sx={{ mt: '15px' }} />
        <Grid sx={{ pt: '15px' }} container>
          <Grid sx={{ alignSelf: 'center' }} xs={12} sm={8} item>
            <Box
              sx={{
                '@media (min-width: 900px)': {
                  // px: '15px',
                },
                '@media (max-width: 900px)': {
                  // px: '7.5px',
                },
                display: 'flex',
                '& p': { fontSize: '1rem' },
              }}
            >
              <Typography sx={{ pr: '10px' }}>
                {cell.postOwner !== null ? cell.postOwner.name : 'Admin'}
              </Typography>


              <Divider orientation="vertical" flexItem />
              <Typography sx={{ px: '10px' }}>
                {Misc.parseAPIDateTime(cell.datePosted)}
              </Typography>
            </Box>
          </Grid>
          <Grid
            xs={12}
            sm={4}
            sx={{
              '& a': { textDecoration: 'none' },
              '@media (min-width: 600px)': {
                textAlign: 'right',
              },
              '@media (max-width: 600px)': {
                textAlign: 'left',
                pt: '15px',
              },
            }}
            item
          >
            {cell.postContent !== null ? (
              <RouterLink to={`../${cell.uniqueLink}/${cell.id}`}>
                <Button variant="contained" sx={{ boxShadow: 'none' }}>
                  Read More
                </Button>
              </RouterLink>
            ) : null}
          </Grid>
        </Grid>*/}
      </Box>
    );
  },
};
