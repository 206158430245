import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import Box from '@mui/material/Box';
import {
  Avatar,
  Button,
  Divider,
  Fab,
  Grid,
  Paper,
  Skeleton,
  Typography,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { Post } from 'types/post';
import { Image } from 'mui-image';
import { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ContentAPI from '../../../api/contentAPI';
import { useDispatch, useSelector } from 'react-redux';
import { Carousel } from 'react-responsive-carousel';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import {
  ImageNotSupported,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  ThumbUp,
  Visibility,
} from '@mui/icons-material';
import Misc from '../../../functions/misc';
import { GlobalReducer, IVisitorData } from 'types/globalReducer';
import { apiSetVisitorInteractionsId } from 'actions/actionCreator';
import contentAPI from '../../../api/contentAPI';
import FavoriteIcon from '@mui/icons-material/Favorite';
import misc from '../../../functions/misc';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { lazyLoad } from 'utils/loadable';
export const PostPage = lazyLoad(
  () => import('./PostPage'),
  module => module.PostPageContent,
);

export function PostPageContent(props: any) {
  const params = useParams();
  const dispatch = useDispatch();

  const [post, setPost] = useState<Post>();
  const [imgFiles, setImgFiles] = useState<File[]>();
  const [postLikeCount, setPostLikeCount] = useState<number>(0);
  const [postViewsCount, setPostViewsCount] = useState<number>(0);
  const [anchorLike, setAnchorLike] = React.useState<null | HTMLElement>(null);
  const [anchorShare, setAnchorShare] = React.useState<null | HTMLElement>(
    null,
  );

  const VisitorData: IVisitorData = useSelector(
    (state: GlobalReducer) => state.navData.visitorData,
  );
  const VisitorInteractions: number[] = useSelector(
    (state: GlobalReducer) => state.navData.userPostInteractionsId,
  );

  useEffect(() => {
    async function getPost() {
      const visitor = await ContentAPI.getVisitorId(dispatch);
      const data: Post = await ContentAPI.getPostById(
        dispatch,
        params.postId ? params.postId : '0',
        visitor.uniqueKey,
      );

      setPost(prev => ({
        ...prev,
        ...data,
      }));

      setPostLikeCount(Number(data.likesCount));
      setPostViewsCount(Number(data.viewsCount));

      if (data.files) {
        const parsedFiles = JSON.parse(data.files);
        setImgFiles(parsedFiles);
      }
    }
    getPost();
  }, [dispatch, params.postId]);

  // Basic skeleton loader
  const SkeletonCont = () => (
    <Paper variant="outlined" sx={{ px: '12px', py: '7.5px', my: '7.5px' }}>
      <Skeleton variant="text" width="50%" animation="wave" />
      <Skeleton
        variant="text"
        width="50%"
        animation="wave"
        sx={{ mb: '7.5px' }}
      />
      <Skeleton
        variant="rectangular"
        height="250px"
        animation="wave"
        sx={{ mb: '7.5px' }}
      />
      <Skeleton variant="text" width="60%" animation="wave" />
    </Paper>
  );

  const ImgCarousel = ({ imgFiles }) => {
    const isDev = false;
    const placeholderImages = [
      'https://placehold.co/600x400',
      'https://placehold.co/600x400',
      'https://placehold.co/600x400',
    ];

    const imagesToUse = isDev ? placeholderImages : imgFiles;

    if (imagesToUse && imagesToUse.length > 0) {
      return (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              '& .carousel-status': { fontSize: '1.2rem', pr: '12px' },
              '& .legend': {
                borderRadius: '0px !important',
                opacity: '1 !important',
                ml: '-50% !important',
                width: '100% !important',
                bottom: '0px !important',
                backgroundColor: 'hsla(0,0%,4%,.5) !important',
                p: '1rem !important',
                fontSize: '16px !important',
                textAlign: 'left !important',
                zIndex: '100 !important',
              },
              alignSelf: 'center',
            }}
          >
            <Carousel
              interval={2000}
              infiniteLoop
              showThumbs={false}
              showIndicators={false}
              swipeable
              renderArrowPrev={(onClickHandler, hasPrev) =>
                hasPrev && (
                  <Button
                    disableRipple
                    sx={{
                      position: 'absolute',
                      zIndex: 2,
                      top: 'calc(50% - 15px)',
                      width: 30,
                      height: 30,
                      left: 15,
                      '& :hover': { backgroundColor: 'none' },
                    }}
                    onClick={onClickHandler}
                  >
                    <KeyboardArrowLeft
                      sx={{
                        fontSize: '6rem',
                        color: 'white',
                        backgroundColor: 'primary.main',
                        opacity: '0.15',
                      }}
                    />
                  </Button>
                )
              }
              renderArrowNext={(onClickHandler, hasNext) =>
                hasNext && (
                  <Button
                    disableRipple
                    sx={{
                      position: 'absolute',
                      zIndex: 2,
                      top: 'calc(50% - 15px)',
                      width: 30,
                      height: 30,
                      right: 15,
                      '& :hover': { backgroundColor: 'none' },
                    }}
                    onClick={onClickHandler}
                  >
                    <KeyboardArrowRight
                      sx={{
                        fontSize: '6rem',
                        color: 'white',
                        backgroundColor: 'primary.main',
                        opacity: '0.15',
                      }}
                    />
                  </Button>
                )
              }
            >
              {imagesToUse.map((imgSrc, index) => (
                <Box
                  key={index}
                  sx={{ position: 'relative', width: '100%', height: 'auto' }}
                >
                  <LazyLoadImage
                    // Intersection Observer-based lazy loading
                    effect="blur"
                    threshold={0.2}
                    src={`${process.env.REACT_APP_API_URL}global/getFile?uniqueId=${post?.uniqueId}&filename=${imgSrc.filename}`}
                    alt={`carousel-img-${index}`}
                    width="100%"
                    height="auto"
                    onError={e => {
                      e.target.onerror = null;
                      e.target.style.display = 'none';
                      e.target.parentNode.appendChild(
                        <ImageNotSupported
                          sx={{
                            fontSize: '6rem',
                            color: 'grey.500',
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                          }}
                        />,
                      );
                    }}
                  />
                </Box>
              ))}
            </Carousel>
          </Box>
        </Box>
      );
    }
    return null;
  };

  return (
    <>
      <Helmet>
        <title>Post</title>
        <meta name="description" content="Post" />
      </Helmet>
      <Box
        sx={{
          '@media (max-width: 1330px)': {},
        }}
      >
        <Grid
          sx={{
            p: '12px',
            '& a': {
              textDecoration: 'none',
            },
            '& .announcementHeader': {
              color: 'text.primary',
            },
            '& .announcementHeader:hover': {
              color: 'text.secondary',
            },
            backgroundColor: 'primary.main',
            '@media (max-width: 900px)': {
              textAlign: 'center',
            },
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
          }}
          container
        >
          <Grid xs={12} sm={12} md={6} lg={6} item>
            <Typography variant="h3" sx={{ color: 'background.paper' }}>
              Post
            </Typography>
          </Grid>
        </Grid>

        <Divider />
        <Box sx={{ py: '15px' }}>
          <Paper
            variant="outlined"
            sx={{
              p: 2,
              '& .postTitleLink': {
                color: 'secondary.main',
                textTransform: 'uppercase',
              },
              '& .postTitleLink:hover': { color: 'primary.main' },
              boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
              backgroundColor: 'background.paper',
              '@media (max-width: 900px)': {
                textAlign: 'center',
              },
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} container alignItems="center">
                <Avatar
                  alt={post?.postOwner?.name}
                  src={post?.postOwner?.avatarUrl}
                />
                <Box ml={2} sx={{ textAlign: 'justify' }}>
                  <Typography variant="body2" color="text.primary">
                    {post?.postOwner?.name}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    {misc.parseAPIDateToDisplay(post?.datePosted)}
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Typography
                  variant="h4"
                  className="postTitleLink"
                  sx={{
                    pb: 2,
                    textAlign: 'start',
                    fontSize: {
                      xs: '1.5rem',
                      sm: '2rem',
                    },
                  }}
                >
                  {post?.title}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                {imgFiles && imgFiles.length > 0 ? (
                  <ImgCarousel imgFiles={imgFiles} />
                ) : null}
              </Grid>

              <Grid item xs={12}>
                <Box sx={{ maxWidth: '100vw !important' }}>
                  {post?.postContent ? (
                    <Box
                      sx={{
                        position: 'relative',
                        '& .quill iframe': {
                          height: '750px',
                        },
                        '& .ql-container': {
                          border: 'none',
                        },
                      }}
                    >
                      <ReactQuill
                        modules={{ toolbar: false }}
                        theme="snow"
                        readOnly
                        value={post?.postContent}
                        onChange={() => {}}
                      />
                    </Box>
                  ) : (
                    <SkeletonCont />
                  )}
                </Box>
              </Grid>

              <Grid
                item
                xs={12}
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Box display="flex" alignItems="center">
                  <Fab
                    sx={{ width: 30, height: 30, minHeight: 'unset' }}
                    aria-label="like"
                    onClick={async e => {
                      setAnchorLike(e.currentTarget);
                      await contentAPI.likePost(
                        dispatch,
                        String(post?.id),
                        VisitorData.uniqueKey,
                      );
                      const userInteractions =
                        await contentAPI.getVisitorInteractions(
                          dispatch,
                          VisitorData.uniqueKey,
                        );
                      dispatch(apiSetVisitorInteractionsId(userInteractions));
                      setPostLikeCount(x =>
                        VisitorInteractions.includes(Number(post?.id))
                          ? --x
                          : ++x,
                      );
                    }}
                  >
                    <ThumbUp
                      sx={{
                        width: 20,
                        height: 20,
                        color:
                          VisitorInteractions &&
                          VisitorInteractions.includes(Number(post?.id))
                            ? 'red'
                            : 'black',
                      }}
                    />
                  </Fab>
                  <Typography variant="body2" sx={{ pl: 1 }}>
                    {postLikeCount}
                  </Typography>
                </Box>

                <Box display="flex" alignItems="center">
                  <Visibility sx={{ width: 20, height: 20 }} />
                  <Typography variant="body2" sx={{ pl: 1 }}>
                    {postViewsCount}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Box>
    </>
  );
}
// import * as React from 'react';
// import { Helmet } from 'react-helmet-async';
// import Box from '@mui/material/Box';
// import { lazyLoad } from 'utils/loadable';
// import {
//   Avatar,
//   Button,
//   Divider,
//   Fab,
//   Grid,
//   Paper,
//   Skeleton,
//   Typography,
// } from '@mui/material';
// import { useParams } from 'react-router-dom';
// import { Post } from 'types/post';
// import { Image } from 'mui-image';
// //import { Editor } from 'react-draft-wysiwyg';
// import { useEffect, useState } from 'react';
// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';
// import ContentAPI from '../../../api/contentAPI';
// import { useDispatch, useSelector } from 'react-redux';
// import { Carousel } from 'react-responsive-carousel';
// import { LazyLoadImage } from 'react-lazy-load-image-component';
// import {
//   ImageNotSupported,
//   KeyboardArrowLeft,
//   KeyboardArrowRight,
//   ThumbUp,
//   Visibility,
// } from '@mui/icons-material';
// import Misc from '../../../functions/misc';
// import { GlobalReducer, IVisitorData } from 'types/globalReducer';
// import { apiSetVisitorInteractionsId } from 'actions/actionCreator';
// import contentAPI from '../../../api/contentAPI';
// import FavoriteIcon from '@mui/icons-material/Favorite';
// import misc from '../../../functions/misc';
// export const PostPage = lazyLoad(
//   () => import('./PostPage'),
//   module => module.PostPageContent,
// );

// const generateImgs = (parsedFiles, post: Post | undefined) => {
//   // console.log(parsedFiles, post);

//   const imageDescription = post?.imageDescription ? post?.imageDescription : [];

//   const imagesJSX = parsedFiles.map((val: any, index) => {
//     let imageLabel = '';
//     if (imageDescription.length > 0) {
//       const imageDesc = imageDescription.find(
//         _val => _val.fileName === val.originalname,
//       );

//       imageLabel = imageDesc?.description;
//     }
//     return (
//       <Box key={index + 'postImages'} sx={{}}>
//         <Image
//           src={`${process.env.REACT_APP_API_URL}global/getFile?uniqueId=${post?.uniqueId}&filename=${val.filename}`}
//           duration={0}
//           fit="cover"
//           // height="750px"
//         />

//         <Typography className="legend" sx={{ width: '100rem' }}>
//           {imageLabel}
//         </Typography>
//       </Box>
//     );
//   });

//   return imagesJSX;
// };

// const SkeletonCont = () => {
//   return (
//     <Paper variant="outlined" sx={{ px: '12px', py: '7.5px', my: '7.5px' }}>
//       <Skeleton variant="text" width="50%" animation="wave" />
//       <Skeleton
//         variant="text"
//         width="50%"
//         animation="wave"
//         sx={{ mb: '7.5px' }}
//       />
//       <Skeleton
//         variant="rectangular"
//         height="250px"
//         animation="wave"
//         sx={{ mb: '7.5px' }}
//       />
//       <Skeleton variant="text" width="60%" animation="wave" />
//     </Paper>
//   );
// };

// export function PostPageContent(props: any) {
//   let params = useParams();

//   const dispatch = useDispatch();

//   const [value, setValue] = useState('');
//   const [contentState, setContentState] = useState<any>(null);
//   const [post, setPost] = useState<Post>();
//   const [imgFiles, setImgFiles] = useState<File[]>();
//   const [JSXimgFiles, setJSXImgFiles] = useState<any>(null);

//   const [anchorLike, setAnchorLike] = React.useState<null | HTMLElement>(null);
//   const [anchorShare, setAnchorShare] = React.useState<null | HTMLElement>(
//     null,
//   );

//   const [postLikeCount, setPostLikeCount] = React.useState<number>(
//     post?.likesCount ? post?.likesCount : 0,
//   );
//   const [postShareCount, setPostShareCount] = React.useState<number>(
//     post?.sharesCount ? post?.sharesCount : 0,
//   );
//   const [postViewsCount, setPostViewsCount] = React.useState<number>(
//     post?.viewsCount ? post?.viewsCount : 0,
//   );

//   const VisitorData: IVisitorData = useSelector(
//     (state: GlobalReducer) => state.navData.visitorData,
//   );
//   const VisitorInteractions: number[] = useSelector(
//     (state: GlobalReducer) => state.navData.userPostInteractionsId,
//   );

//   // const findPostByID = (id: number) => {
//   //   const _data = postData.find(item => item.id === id);
//   //   setContentState(_data?.postContent);
//   //   return _data;
//   // };

//   const visitorData: IVisitorData = useSelector(
//     (state: GlobalReducer) => state.navData.visitorData,
//   );

//   const onContentStateChange = contentState => {
//     setContentState(contentState);
//   };

//   useEffect(() => {
//     async function getPost() {
//       const VisitorData = await ContentAPI.getVisitorId(dispatch);

//       const data: Post = await ContentAPI.getPostById(
//         dispatch,
//         params.postId ? params.postId : '0',
//         VisitorData.uniqueKey,
//       );
//       setPost(data);
//       setPostLikeCount(Number(data.likesCount));
//       setPostViewsCount(Number(data.viewsCount));

//       if (data.files !== '' || data.files !== null) {
//         const parsedFiles = JSON.parse(data.files);
//         //console.log(parsedFiles);
//         setImgFiles(parsedFiles);
//         setJSXImgFiles(generateImgs(parsedFiles, data));
//       }
//     }
//     getPost();
//     //@ts-ignore

//     //setPost(findPostByID(Number(params.postId)));
//     //console.log(findPostByID(Number(params.postId)));
//   }, [params.postId]);

//   const ImgCarousel = ({ imgFiles, scrollPosition }) => {
//     const isDev = false;
//     const placeholderImages = [
//       'https://placehold.co/600x400',
//       'https://placehold.co/600x400',
//       'https://placehold.co/600x400',
//     ];

//     const imagesToUse = isDev ? placeholderImages : imgFiles;

//     if (imagesToUse !== null && imagesToUse.length > 0) {
//       return (
//         <Box
//           sx={{
//             width: '100%',
//             display: 'flex',
//             justifyContent: 'center',
//           }}
//         >
//           <Box
//             sx={{
//               '& .carousel-status': { fontSize: '1.2rem', pr: '12px' },
//               '& .legend': {
//                 borderRadius: '0px !important',
//                 opacity: '1 !important',
//                 ml: '-50% !important',
//                 width: '100% !important',
//                 bottom: '0px !important',
//                 backgroundColor: 'hsla(0,0%,4%,.5) !important',
//                 p: '1rem !important',
//                 fontSize: '16px !important',
//                 textAlign: 'left !important',
//                 zIndex: '100 !important',
//               },
//               alignSelf: 'center',
//             }}
//           >
//             <Carousel
//               interval={2000}
//               infiniteLoop
//               showThumbs={false}
//               showIndicators={false}
//               swipeable={true}
//               renderArrowPrev={(onClickHandler, hasPrev) =>
//                 hasPrev && (
//                   <Button
//                     disableRipple
//                     sx={{
//                       position: 'absolute',
//                       zIndex: 2,
//                       top: 'calc(50% - 15px)',
//                       width: 30,
//                       height: 30,
//                       left: 15,
//                       '& :hover': { backgroundColor: 'none' },
//                     }}
//                     onClick={onClickHandler}
//                   >
//                     <KeyboardArrowLeft
//                       sx={{
//                         fontSize: '6rem',
//                         color: 'white',
//                         backgroundColor: 'primary.main',
//                         opacity: '0.15',
//                       }}
//                     />
//                   </Button>
//                 )
//               }
//               renderArrowNext={(onClickHandler, hasNext) =>
//                 hasNext && (
//                   <Button
//                     disableRipple
//                     sx={{
//                       position: 'absolute',
//                       zIndex: 2,
//                       top: 'calc(50% - 15px)',
//                       width: 30,
//                       height: 30,
//                       right: 15,
//                       '& :hover': { backgroundColor: 'none' },
//                     }}
//                     onClick={onClickHandler}
//                   >
//                     <KeyboardArrowRight
//                       sx={{
//                         fontSize: '6rem',
//                         color: 'white',
//                         backgroundColor: 'primary.main',
//                         opacity: '0.15',
//                       }}
//                     />
//                   </Button>
//                 )
//               }
//             >
//               {imagesToUse.map((imgSrc, index) => (
//                 <Box
//                   key={index}
//                   sx={{ position: 'relative', width: '100%', height: 'auto' }}
//                 >
//                   <LazyLoadImage
//                     scrollPosition={scrollPosition}
//                     src={`${process.env.REACT_APP_API_URL}global/getFile?uniqueId=${post?.uniqueId}&filename=${imgSrc.filename}`}
//                     effect="blur"
//                     alt={`carousel-img-${index}`}
//                     width="100%"
//                     height="auto"
//                     onError={e => {
//                       e.target.onerror = null;
//                       e.target.style.display = 'none';
//                       e.target.parentNode.appendChild(
//                         <ImageNotSupported
//                           sx={{
//                             fontSize: '6rem',
//                             color: 'grey.500',
//                             position: 'absolute',
//                             top: '50%',
//                             left: '50%',
//                             transform: 'translate(-50%, -50%)',
//                           }}
//                         />,
//                       );
//                     }}
//                   />
//                 </Box>
//               ))}
//             </Carousel>
//           </Box>
//         </Box>
//       );
//     } else {
//       return null;
//     }
//   };
//   return (
//     <>
//       <Helmet>
//         <title>Post</title>
//         <meta name="description" content="Post" />
//       </Helmet>
//       <Box
//         sx={{
//           '@media (max-width: 1330px)': {
//             // p: '15px',
//           },
//         }}
//       >
//         <Grid
//           sx={{
//             // mt: '7.5px',
//             p: '12px',
//             '& a': {
//               textDecoration: 'none',
//             },
//             '& .announcementHeader': {
//               color: 'text.primary',
//             },
//             '& .announcementHeader:hover': {
//               color: 'text.secondary',
//             },
//             backgroundColor: 'primary.main',
//             '@media (max-width: 900px)': {
//               textAlign: 'center',
//             },
//             '@media (min-width: 900px)': {},
//             boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
//           }}
//           container
//         >
//           <Grid xs={12} sm={12} md={6} lg={6} item>
//             <Typography variant="h3" sx={{ color: 'background.paper' }}>
//               Post
//             </Typography>
//           </Grid>
//         </Grid>

//         <Divider />
//         <Box sx={{ py: '15px' }}>
//           {/* <Typography
//             variant="h4"
//             sx={{
//               my: '7.5px',
//               '@media (max-width: 1330px)': {
//                 textAlign: 'center',
//               },
//               '@media (min-width: 1330px)': {},
//             }}
//           >
//             {post?.title}
//           </Typography> */}
//           <Paper
//             variant="outlined"
//             sx={{
//               p: 2,
//               '& .postTitleLink': {
//                 color: 'secondary.main',
//                 textTransform: 'uppercase',
//               },
//               '& .postTitleLink:hover': { color: 'primary.main' },
//               '& a': {
//                 textDecoration: 'none',
//               },
//               '& .announcementHeader': {
//                 color: 'text.primary',
//               },
//               '& .announcementHeader:hover': {
//                 color: 'text.secondary',
//               },
//               boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
//               backgroundColor: 'background.paper',
//               '@media (max-width: 900px)': {
//                 textAlign: 'center',
//               },
//             }}
//           >
//             <Grid container spacing={2}>
//               <Grid item xs={12} container alignItems="center">
//                 <Avatar
//                   alt={post?.postOwner?.name}
//                   src={post?.postOwner?.avatarUrl}
//                 />
//                 <Box ml={2} sx={{ textAlign: 'justify' }}>
//                   <Typography variant="body2" color="text.primary">
//                     {post?.postOwner?.name}
//                   </Typography>
//                   <Typography variant="caption" color="text.secondary">
//                     {misc.parseAPIDateToDisplay(post?.datePosted)}
//                   </Typography>
//                 </Box>
//               </Grid>
//               <Grid item xs={12}>
//                 <Typography
//                   variant="h4"
//                   className="postTitleLink"
//                   sx={{
//                     pb: 2,
//                     textAlign: 'start',
//                     fontSize: {
//                       xs: '1.5rem', // font size for mobile devices
//                       sm: '2rem', // font size for small devices
//                       // md: '2.5rem', // font size for medium devices
//                       // lg: '3rem', // font size for large devices
//                     },
//                   }}
//                 >
//                   {post?.title}
//                 </Typography>
//               </Grid>
//               <Grid item xs={12}>
//                 {imgFiles !== undefined &&
//                 imgFiles !== null &&
//                 imgFiles.length > 0 ? (
//                   <ImgCarousel imgFiles={imgFiles} />
//                 ) : null}
//               </Grid>

//               <Grid item xs={12}>
//                 <Box sx={{ maxWidth: '100vw !important' }}>
//                   {post?.postContent !== null && post?.postContent !== '' ? (
//                     <Box
//                       sx={{
//                         position: 'relative',
//                         '& .quill iframe': {
//                           height: '750px',
//                         },
//                         '& .ql-container': {
//                           border: 'none',
//                         },
//                       }}
//                     >
//                       <ReactQuill
//                         modules={{ toolbar: false }}
//                         theme="snow"
//                         readOnly
//                         value={post?.postContent}
//                         onChange={() => {}}
//                       />
//                     </Box>
//                   ) : (
//                     <>
//                       {post?.postContent !== null &&
//                       post?.postContent !== '' ? (
//                         <SkeletonCont />
//                       ) : null}
//                     </>
//                   )}
//                 </Box>
//               </Grid>
//               <Grid
//                 item
//                 xs={12}
//                 container
//                 justifyContent="space-between"
//                 alignItems="center"
//               >
//                 <Box display="flex" alignItems="center">
//                   <Fab
//                     sx={{ width: 30, height: 30, minHeight: 'unset' }}
//                     aria-label="like"
//                     onClick={async e => {
//                       setAnchorLike(e.currentTarget);
//                       await contentAPI.likePost(
//                         dispatch,
//                         String(post?.id),
//                         VisitorData.uniqueKey,
//                       );
//                       let _userInteractions =
//                         await contentAPI.getVisitorInteractions(
//                           dispatch,
//                           VisitorData.uniqueKey,
//                         );
//                       dispatch(apiSetVisitorInteractionsId(_userInteractions));
//                       setPostLikeCount(x =>
//                         VisitorInteractions.includes(Number(post?.id))
//                           ? --x
//                           : ++x,
//                       );
//                     }}
//                   >
//                     <ThumbUp
//                       sx={{
//                         width: 20,
//                         height: 20,
//                         color:
//                           VisitorInteractions &&
//                           VisitorInteractions.includes(Number(post?.id))
//                             ? 'red'
//                             : 'black',
//                       }}
//                     />
//                   </Fab>
//                   <Typography variant="body2" sx={{ pl: 1 }}>
//                     {postLikeCount}
//                   </Typography>
//                 </Box>
//                 <Box display="flex" alignItems="center">
//                   <Visibility sx={{ width: 20, height: 20 }} />
//                   <Typography variant="body2" sx={{ pl: 1 }}>
//                     {postViewsCount}
//                   </Typography>
//                 </Box>
//               </Grid>
//             </Grid>
//           </Paper>
//           {/* {typeof post?.postBanner === 'string' ? (
//             post?.postBanner !== '' ? (
//               <Box sx={{ my: '15px' }}>
//                 <Image
//                   src={require(`../../../assets/uploads/${post.}`)}
//                   duration={0}
//                   fit="contain"
//                   height="40rem"
//                 />
//               </Box>
//             ) : null
//           ) : null} */}
//         </Box>
//       </Box>
//     </>
//   );
// }
