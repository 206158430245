/**
 * index.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

// Use consistent styling
import 'sanitize.css/sanitize.css';

// Import root app
import { App } from 'app';

import { HelmetProvider } from 'react-helmet-async';

//import { configureAppStore } from 'store/configureStore';

import reportWebVitals from 'reportWebVitals';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import { createStore } from 'redux';
import { yellow, red } from '@mui/material/colors';

// Initialize languages
//import './locales/i18n';
import reducer from './reducer/reducer';
import { applyMiddleware } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import rootSaga from 'sagas/sagas';
import { GlobalDocumentViewer } from 'app/components/globalDocumentViewer';
//const store = configureAppStore();

import { SnackbarProvider, enqueueSnackbar } from 'notistack';

const sagaMiddleware = createSagaMiddleware();
const store = createStore(reducer, applyMiddleware(sagaMiddleware));

const MOUNT_NODE = document.getElementById('root') as HTMLElement;

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#1ab962',
    },
    secondary: {
      main: '#0a80b5',
    },
    background: {
      paper: '#fcfcfc',
    },
  },
  components: {
    // Name of the component
    MuiPaper: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          borderRadius: '0px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          borderRadius: '0px',
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          borderRadius: '0px',
        },
        item: {
          // Some CSS
          borderRadius: '0px',
        },
        container: {
          // Some CSS
          borderRadius: '0px',
        },
      },
    },
  },
});

//
ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <HelmetProvider>
        <React.StrictMode>
          <SnackbarProvider>
            <GlobalDocumentViewer />
            <App />
          </SnackbarProvider>
        </React.StrictMode>
      </HelmetProvider>
    </ThemeProvider>
  </Provider>,
  MOUNT_NODE,
  () => {
    sagaMiddleware.run(rootSaga);
  },
);

// Hot reloadable translation json files
// if (module.hot) {
//   module.hot.accept(['./locales/i18n'], () => {
//     // No need to render the App again because i18next works with the hooks
//   });
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
