import React from 'react';
import { Image } from 'mui-image';
import { Divider, Typography } from '@mui/material';
import { Box } from '@mui/system';
import Clock from 'react-live-clock';
import tigbauanLogoCircle from '../../../assets/tigbauanLogoCircle.png';
export const MastHead = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        mt: '58px',
        background:
          'linear-gradient(90deg, rgba(34,42,231,1) 0%, rgba(7,205,113,1) 100%)',
        boxShadow: 'rgb(0 0 0 / 20%) 0px 2px 4px 0px',
        backgroundColor: 'primary.main',
        '@media (min-width: 1330px)': {
          minHeight: '170px',
        },
        '@media (max-width: 1330px)': {
          maxHeight: '350px',
          minHeight: '350px',
        },
        '@media (max-width: 445px)': {
          maxHeight: '280px',
          minHeight: '280px',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          //backgroundColor: '#fbc02d',

          '& .imgTransparencySeal': { float: 'right' },
          '@media (min-width: 1330px)': {
            flexDirection: 'row',
            width: '80rem',
          },
          '@media (max-width: 1330px)': { flexDirection: 'column', py: 2 },
        }}
      >
        {/* <Box
          sx={{
            position: 'absolute',
            zIndex: 0,
            maxWidth: '80rem',
            // maxHeight: '240px',
            overflow: 'hidden',
          }}
        >
          <Image
            src={tigbauanHeaderBG}
            duration={0}
            sx={{
              // mt: -10,
              '& -moz-transform': 'scaleX(-1)',
              '& -o-transform': 'scaleX(-1)',
              '& -webkit-transform': 'scaleX(-1)',
              transform: 'scaleX(-1) translate(0%,-1%)',
              filter: 'FlipH',
              '& -ms-filter': 'FlipH',
            }}
            fit="cover"
          />
        </Box>
        <Box
          sx={{
            position: 'absolute',

            zIndex: 0,
            mt: 5.5,
            backgroundColor: 'rgba(255, 255, 255, 0.65)',
            height: '140px',
            width: '50rem',
            // borderTopRightRadius: '5px',
            // borderBottomRightRadius: '5px',
          }}
        ></Box> */}
        <Box sx={{ flex: 3, alignSelf: 'center' }}>
          <Box
            sx={{
              // position: 'absolute',
              zIndex: 2,
              '@media (min-width: 1330px)': {
                // py: '30px',
                // pl: 5,
              },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                '@media (max-width: 1330px)': {
                  flexDirection: 'column',
                  alignItems: 'center',
                },
              }}
            >
              <Image
                src={tigbauanLogoCircle}
                duration={0}
                fit="fill"
                width="135px"
              />
              <Box
                sx={{
                  alignSelf: 'center',
                  p: '15px',

                  // backgroundColor: 'rgba(255, 255, 255, 0.7)',
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    fontFamily: '"Times New Roman", Times, serif',
                    letterSpacing: '3px',
                    color: 'background.paper',
                    //color: 'white',

                    '@media (max-width: 720px)': { fontSize: '3vw' },
                    '@media (max-width: 1330px)': {
                      textAlign: 'center',
                    },
                  }}
                >
                  Republic of the Philippines
                </Typography>
                <Divider
                  orientation="horizontal"
                  sx={{ borderWidth: '1px', borderColor: 'background.paper' }}
                />
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 500,
                    color: 'background.paper',

                    //color: 'white',
                    fontFamily: '"Times New Roman", Times, serif',
                    '@media (max-width: 720px)': {
                      fontSize: '5vw',
                      textAlign: 'center',
                    },
                  }}
                >
                  MUNICIPALITY OF TIGBAUAN
                </Typography>
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 500,
                    color: 'background.paper',

                    //color: 'white',
                    fontFamily: '"Times New Roman", Times, serif',
                    fontStyle: 'italic',
                    fontSize: '1.2rem',
                    '@media (max-width: 720px)': {
                      fontSize: '0.6rem',
                      textAlign: 'center',
                    },
                    '@media (max-width: 1330px)': {
                      textAlign: 'center',
                    },
                  }}
                >
                  "Tigbaw Mangibabaw"
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={{ flex: 1, zIndex: 5 }}>
          <Box
            sx={{
              display: 'flex',
              '@media (min-width: 1331px)': {
                textAlign: 'right',
                flexDirection: 'column',
                py: '30px',
              },
              '@media (max-width: 1331px)': {
                flexDirection: 'row',
                justifyContent: 'center',
              },
            }}
          >
            <Typography
              sx={{
                '@media (min-width: 1331px)': {
                  flex: 1,
                },
                '@media (max-width: 1331px)': {
                  pr: 1,
                },
                '@media (max-width: 720px)': { fontSize: '3vw' },
                color: 'background.paper',
              }}
            >
              Philippine Standard Time
            </Typography>
            <Box
              sx={{
                '@media (min-width: 1331px)': {
                  flex: 1,
                  fontSize: '0.8vw',
                },
                '@media (max-width: 720px)': { fontSize: '3vw' },
                color: 'background.paper',
              }}
            >
              <Clock
                format={'dddd, LL, LTS'}
                ticking={true}
                timezone={'Asia/Hong_Kong'}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
