import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import Box from '@mui/material/Box';
import { lazyLoad } from 'utils/loadable';
import { Divider, Typography, Paper } from '@mui/material';
import { Image } from 'mui-image';
export const AssessorsOffice = lazyLoad(
  () => import('./AssessorsOffice'),
  module => module.AssessorsOfficeContent,
);

export function AssessorsOfficeContent(props: any) {
  return (
    <>
      <Helmet>
        <title>Assessors Office</title>
        <meta name="description" content="Assessors Office" />
      </Helmet>
      <Box sx={{ p: '15px' }}>
        <Paper
          variant="outlined"
          sx={{
            // mt: '7.5px',
            p: '12px',
            '& a': {
              textDecoration: 'none',
            },
            '& .announcementHeader': {
              color: 'text.primary',
            },
            '& .announcementHeader:hover': {
              color: 'text.secondary',
            },
            backgroundColor: 'primary.main',
            '@media (max-width: 900px)': {
              textAlign: 'center',
            },
            '@media (min-width: 900px)': {},
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
          }}
        >
          <Typography variant="h4" sx={{ color: 'background.paper' }}>Assessors Office</Typography>
        </Paper>
        <Paper
          variant="outlined"
          sx={{
            my: '15px',
            p: '15px',
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
          }}
        >
          <Typography sx={{ textAlign: 'justify' }}>
            The office ensures the implementation of laws and policies governing
            appraisal and assessment of real properties for taxation purposes.
            It recommends possible ways and means to enhance practices in the
            valuation of real properties.
          </Typography>
        </Paper>
        {/* {!props.noHeaderImg ? (
          <Paper variant="outlined" sx={{ my: '15px', p: '15px' }}>
            <Image
              src={require(`../../../assets/localOffice/${props.specialLink}.jpg`)}
              duration={0}
              fit="contain"
              height="40rem"
            />
          </Paper>
        ) : null} */}
        <Paper
          variant="outlined"
          sx={{
            // mt: '7.5px',
            p: '12px',
            '& a': {
              textDecoration: 'none',
            },
            '& .announcementHeader': {
              color: 'text.primary',
            },
            '& .announcementHeader:hover': {
              color: 'text.secondary',
            },
            backgroundColor: 'primary.main',
            '@media (max-width: 900px)': {
              textAlign: 'center',
            },
            '@media (min-width: 900px)': {},
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
          }}
        >
          <Typography variant="h4" sx={{ color: 'background.paper' }}>
            Assessor’s Office Contact Information
          </Typography>
        </Paper>

        <Paper
          variant="outlined"
          sx={{
            my: '15px',
            p: '15px',
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
          }}
        >
          <Typography sx={{ textAlign: 'justify' }}>
            Municipal Assessor’s Office
          </Typography>
          <Typography sx={{ textAlign: 'justify' }}>
            Ground Floor, Tigbauan Municipal Bldg., Tejero St., Brgy. 9,
            Tigbauan, Iloilo
          </Typography>
          <Typography sx={{ textAlign: 'justify' }}>511-7509</Typography>
        </Paper>
      </Box>
    </>
  );
}
