import React from 'react';
import { Image } from 'mui-image';

import { Link, Typography } from '@mui/material';
import { Box } from '@mui/system';
import phSeal from '../../../assets/phSeal.png';
export const StandardFooter = (props: any) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        justifyContent: 'end',
      }}
    >
      <Box
        sx={{
          // mt: 2,
          py: 1.5,
          backgroundColor: '#efefef',
        }}
      >
        <Typography
          sx={{
            fontSize: '0.85rem',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          This Website is maintained by &nbsp;
          <Link
            href="https://knodesoft.com"
            target="_blank"
            rel="noopener noreferrer"
            sx={{ fontSize: '0.85rem', color: 'black', textDecoration: 'none' }}
          >
            Knodesoft™
          </Link>
        </Typography>
      </Box>
      <Box
        sx={{
          '@media (max-width: 1050px)': {
            maxHeight: '400px',
            minHeight: '400px',
          },
          '@media (min-width: 1050px)': {
            maxHeight: '200px',
            minHeight: '200px',
          },

          py: '20px',
          backgroundColor: '#efefef',
          color: '#505050',
          fontSize: '0.7rem',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            width: '80rem',
            display: 'flex',
            '@media (max-width: 1050px)': {
              flexDirection: 'column',
              justifyContent: 'center',
            },
          }}
        >
          <Box sx={{ flex: 4 }}>
            <Box sx={{ display: 'flex' }}>
              <Image
                src={phSeal}
                duration={0}
                fit="contain"
                width="148px"
                height="148px"
                sx={{ px: '10.5px' }}
              />
              <Box sx={{ px: '10.5px' }}>
                <Typography
                  variant="h4"
                  sx={{
                    mt: '3.2px',
                    mb: '8px',
                    fontSize: '0.9em',
                    textTransform: 'uppercase',
                    fontWeight: 'bold',
                  }}
                >
                  Republic of the Philippines
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 'normal',
                    mb: '1.25rem',
                    lineHeight: '1.6',
                    fontSize: '11px',
                  }}
                >
                  All content is in the public domain unless otherwise stated.
                </Typography>
                {/* <Typography>
                Official Website of the Municipality of Tigbauan
              </Typography> */}
              </Box>
            </Box>
          </Box>
          <Box sx={{ flex: 5 }}>
            <Box
              sx={{
                display: 'flex',
                '& a': { color: '#505050', textDecoration: 'none' },
                '& a:hover': {
                  textDecoration: 'underline',
                  textDecorationColor: '#505050',
                },
              }}
            >
              <Box sx={{ ml: '8.3%', flex: 1 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography
                    variant="h4"
                    sx={{
                      mt: '3.2px',
                      mb: '8px',
                      fontSize: '0.9em',
                      textTransform: 'uppercase',
                      fontWeight: 'bold',
                    }}
                  >
                    About GOVPH
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 'normal',
                      mb: '1.25rem',
                      lineHeight: '1.6',
                      fontSize: '11px',
                    }}
                  >
                    Learn more about the Philippine government, its structure,
                    how government works and the people behind it.
                  </Typography>
                  <Link
                    href="http://www.gov.ph/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    GOV.PH
                  </Link>
                  <Link
                    href="http://www.gov.ph/data"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Open Data Portal
                  </Link>
                  <Link
                    href="http://www.officialgazette.gov.ph/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Official Gazette
                  </Link>
                </Box>
              </Box>
              <Box sx={{ ml: '8.3%', flex: 1 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography
                    variant="h4"
                    sx={{
                      mt: '3.2px',
                      mb: '8px',
                      fontSize: '0.9em',
                      textTransform: 'uppercase',
                      fontWeight: 'bold',
                    }}
                  >
                    GOVERNMENT LINKS
                  </Typography>
                  <Link
                    href="http://president.gov.ph/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Office of the President
                  </Link>
                  <Link
                    href="http://ovp.gov.ph/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Office of the Vice President
                  </Link>
                  <Link
                    href="http://www.senate.gov.ph/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Senate of the Philippines
                  </Link>
                  <Link
                    href="http://www.congress.gov.ph/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    House of Representatives
                  </Link>
                  <Link
                    href="http://sc.judiciary.gov.ph/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Supreme Court
                  </Link>
                  <Link
                    href="http://ca.judiciary.gov.ph/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Court of Appeals
                  </Link>
                  <Link
                    href="http://sb.judiciary.gov.ph/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Sandiganbayan
                  </Link>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
